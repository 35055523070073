const Service = ({ title, description, type }) => {
  const author = 'Stas Prohetamine'
      , _title = `${author} | ${title === '404' ? title : `${title} (${type}) — Сервис`}`
      , _description = description.replace(/<[^>]*>/g, '')

  return ({
    title: { tag: 'title', attributes: { innerHTML: _title }},
    og_title: { tag: 'meta', attributes: { property: 'og:title', content: _title }},
    author: { tag: 'meta', attributes: { name: 'author', content: author }},
    description: { tag: 'meta', attributes: { name: 'description', content: _description }},
    og_description: { tag: 'meta', attributes: { property: 'og:description', content: _description }},
    keywords: { tag: 'meta', attributes: { name: 'keywords', content: _title }},
  })
}

export default Service
