import React, { useState } from 'react'
import { motion } from 'framer-motion'
import { Link } from 'react-router-dom'
import { observer } from 'mobx-react-lite'

import mainStyles from './main.module.css'
import globalLightStyles from './../../styles/light.module.css'
import globalDarkStyles from './../../styles/dark.module.css'

const dark = { ...globalDarkStyles, ...mainStyles }
    , light = { ...globalLightStyles, ...mainStyles }

import store from './../../../../store'

const ButtonLink = observer(({ to, children }) => {
  const styles = store.theme === 'light'
                  ? light
                  : dark

  const [hover, setHover] = useState(false)

  return (
    <div className={styles.overflow}>
      <Link
        className={styles.link}
        to={to}
        onMouseOut={() => setHover(false)}
        onMouseOver={() => setHover(true)}
      >
        <div className={styles.buttonLink}><b>{children}</b></div>
        <motion.svg
          animate={
            hover
              ? {
                transform: [`translateX(0px)`, `translateX(3px)`, `translateX(0px)`, `translateX(3px)`]
              }
              : {
                transform: `translateX(0px)`
              }
          }
          className={styles.icon}
          width='16'
          height='12'
          viewBox='0 0 16 12'
          fill='none'
          xmlns='http://www.w3.org/2000/svg'
        >
          <path d='M14.5303 6.52986C14.8232 6.23697 14.8232 5.76209 14.5303 5.4692L9.7574 0.696232C9.4645 0.403339 8.9896 0.403339 8.6967 0.696232C8.4038 0.989126 8.4038 1.464 8.6967 1.75689L12.9393 5.99953L8.6967 10.2421C8.4038 10.535 8.4038 11.0099 8.6967 11.3028C8.9896 11.5957 9.4645 11.5957 9.7574 11.3028L14.5303 6.52986ZM-2 6.74953H14V5.24953H-2V6.74953Z' className={styles.buttonLink} strokeWidth='0.6' />
        </motion.svg>
      </Link>
    </div>
  )
})

export default ButtonLink
