import React, { useState, useEffect } from 'react'
import { useWindowSize } from '@react-hook/window-size'
import styled from 'styled-components'
import { observer } from 'mobx-react-lite'

import lightStyles from './light.module.css'
import darkStyles from './dark.module.css'

const dark = darkStyles
    , light = lightStyles

import store from './../../../../store'

const Image = styled.img`
  box-sizing: border-box;
  border-radius: ${props => props.width > 640 ? '10px' : '8px'};
  background-size: cover;
  background-position: center center;
  max-width: 900px;
  margin-top: 10px;
  margin-bottom: 20px;
  background-color: #fff;
  pointer-events: none;
  width: 100%;
  width: ${
    ({ image, articleWidth, windowHeight }) => image.width === image.height
            ? `${articleWidth > 400 ? 400 : articleWidth - 100}px`
            : articleWidth > windowHeight
                ? image.width > image.height
                    ? '100%'
                    : '50%'
                : image.width > image.height
                    ? '100%'
                    : '100%'
  };
  min-width: 100px;
`

const Image_ = observer(props => {
  const styles = store.theme === 'light'
                  ? light
                  : dark

  const [image, setImage] = useState(null)
  const [width_, height] = useWindowSize()
  const width = width_ - 20

  useEffect(() => {
    const img = new window.Image()
    img.onload = () => setImage(img)
    img.src = props.src

    return () => img.onload = null
  }, [props.src])

  const articleWidth = (width - (width > 1200 ? 300 : width > 640 ? 142 : 90))

  return image ? (
    <Image
      src={image}
      {...props}
      className={styles.image}
      articleWidth={articleWidth}
      windowHeight={height}
      image={image}
      theme={store.theme}
    />
  )
  : null
})

export default Image_
