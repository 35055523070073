import React from 'react'
import { observer } from 'mobx-react-lite'
import { Link } from 'react-router-dom'

import mainStyles from './../../styles/main.module.css'
import lightStyles from './light.module.css'
import darkStyles from './dark.module.css'
import globalLightStyles from './../../styles/light.module.css'
import globalDarkStyles from './../../styles/dark.module.css'

const dark = { ...mainStyles, ...globalDarkStyles, ...darkStyles }
    , light = {  ...mainStyles, ...globalLightStyles, ...lightStyles }

import store from './../../../../store'

const Filter = observer(({ tags, path }) => {
  const styles = store.theme === 'light'
                  ? light
                  : dark

  return (
    <>
      <h2 className={styles.titleH2}>Теги</h2>
      <p className={styles.description}>
        {
          tags.map((tag, key) => (
            <Link
              className={styles.tag}
              key={key}
              to={`${path}?tag=${tag}`}
            >
              #{tag}
            </Link>
          ))
        }
      </p>
    </>
  )
})

export default Filter
