import cyrillicToTranslit from 'cyrillic-to-translit-js'

const parseItems = (items, index, offset, path) => {
  const { title, html, image } = (items[index + offset] || {})

  if (title && html && image) {
    return {
      title: (t => t.length > 20 ? title.slice(0, 17)+'...' : title)(title),
      description: html.find(tag => tag[0] === 'p' && tag[1].replace(/<[^>]*>/g, '').length > 30)[1].replace(/<[^>]*>/g, '').slice(0, 27)+'...',
      image,
      link: [`${path}/${cyrillicToTranslit().transform(title.toLowerCase(), '-').replace(/[^\w]/gi, '-')}`, 'Читать'],
    }
  } else {
    return false
  }
}

const useArticleNavigation = (items, index, path) => {
  const navigate = []

  const prevFirst = parseItems(items, index, -1, path)
  prevFirst && navigate.push(prevFirst)

  const prevLast = parseItems(items, index, -2, path)
  prevLast && navigate.push(prevLast)

  const nextFirst = parseItems(items, index, 1, path)
  nextFirst && navigate.push(nextFirst)

  const nextLast = parseItems(items, index, 2, path)
  nextLast && navigate.push(nextLast)

  return navigate
}

export default useArticleNavigation
