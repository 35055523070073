import React from 'react'
import { Routes, Route } from 'react-router-dom'
import { motion, AnimatePresence } from 'framer-motion'
import { useLocation } from 'react-router-dom'
import { observer } from 'mobx-react-lite'

import mainStyles from './styles/main.module.css'

import Home from './home'
import Services from './services'
import Service from './service'
import Projects from './projects'
import Project from './project'
import Articles from './articles'
import Achievements from './achievements'
//import HistorySound from './history'
import Lenta from './lenta'
import Merch from './merch'

import store from './../../store'

const NotFound = () => {
  window.location.href = '/'
  return (<div></div>)
}

const Pages = observer(() => {
  const location = useLocation()

  return (
    <AnimatePresence>
      <motion.div
        key={location.pathname}
        initial={location.pathname === '/' ? { opacity: 0, display: 'none', transition: { duration: 0 } } : { opacity: 0, display: 'none', transition: { duration: 0 } }}
        animate={location.pathname === '/' ? { display: 'flex', opacity: 1, transition: { duration: 0 } } : { display: 'flex', opacity: 1, transition: { duration: 0.5 } }}
        exit={{ opacity: 0, display: 'none', transition: { duration: 0 } }}
        transition={location.pathname === '/' ? {} : { duration: 0.5 }}
        className={mainStyles.body}
        style={
          store.isMenu
            ? {
              width: '100%'
            }
            : { /* width: ... */ }
        }
      >
        <Routes>
          <Route exact path='/' element={<Home />} />
          <Route exact path='/services' element={<Services />} />
          <Route exact path='/service/:title' element={<Service />} />
          <Route exact path='/projects' element={<Projects />} />
          <Route exact path='/project/:title' element={<Project />} />
          <Route exact path='/articles' element={<Articles />} />
          <Route exact path='/achievements' element={<Achievements />} />
          {/*<Route exact path='/history' element={<HistorySound />} />*/}
          <Route exact path='/lenta' element={<Lenta />} />
          <Route exact path='/merch' element={<Merch />} />
          <Route path='*' element={<NotFound />} />
        </Routes>
      </motion.div>
    </AnimatePresence>
  )
})

export default Pages
