const Articles = () => {
  const author = 'Stas Prohetamine'
      , title = `${author} | Статьи`
      , description = 'Пишу о своем деле. О идеях и проектах, некоторые из моих статей призваны быть руководством к действию, а какие-то могут быть и провокацией. Вообще писать статьи — это очень тяжело и трудно, мне не платят, я делаю это просто потому что я благодарен тем на чьих статьях я сам учился программировать или собирать какие-то устройства.'

  return ({
    title: { tag: 'title', attributes: { innerHTML: title }},
    og_title: { tag: 'meta', attributes: { property: 'og:title', content: title }},
    author: { tag: 'meta', attributes: { name: 'author', content: author }},
    description: { tag: 'meta', attributes: { name: 'description', content: description }},
    og_description: { tag: 'meta', attributes: { property: 'og:description', content: description }},
    keywords: { tag: 'meta', attributes: { name: 'keywords', content: title }},
  })
}

export default Articles
