import React from 'react'
import { observer } from 'mobx-react-lite'

import globalLightStyles from './../../styles/light.module.css'
import globalDarkStyles from './../../styles/dark.module.css'

const dark = globalDarkStyles
    , light = globalLightStyles

import store from './../../../../store'

import Image from './../image'

const CreateArticle = observer(({ codeHtml }) => {
  const styles = store.theme === 'light'
                  ? light
                  : dark

  return (
    <div className={styles.wrapper}>
      {
        codeHtml.map(([name, value, option], key) => {
          if (name === 'h1') {
            return (
              <h1 key={key} className={key === 0 ? styles.titleH1 + ' ' + styles.titleMarginTop : styles.titleH1} dangerouslySetInnerHTML={{ __html: value }} />
            )
          }

          if (name === 'h2') {
            return (
              <h2 key={key} className={styles.titleH2} dangerouslySetInnerHTML={{ __html: value }} />
            )
          }

          if (name === 'p') {
            return (
              <p className={styles.description} key={key} dangerouslySetInnerHTML={{ __html: value }} />
            )
          }

          if (name === 'q') {
            return (
              <p className={styles.quote} key={key} dangerouslySetInnerHTML={{ __html: value }} />
            )
          }

          if (name === 'img') {
            return (
              <Image alt={option} src={value} key={key} />
            )
          }
        })
      }
    </div>
  )
})

export default CreateArticle
