import { Component } from 'react'

class Error extends Component {
  constructor(props) {
    super(props)
  }

  componentDidCatch() {
    window.localStorage.clear()
    window.location.reload()
  }

  render() {
    return this.props.children
  }
}

export default Error
