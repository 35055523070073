import React, { useEffect, useState } from 'react'
import { observer } from 'mobx-react-lite'
import { Player } from '@lottiefiles/react-lottie-player'
import useScrollPosition from '@react-hook/window-scroll'
import { isBrowser } from 'react-device-detect'

import lightStyles from './styles/light.module.css'
import darkStyles from './styles/dark.module.css'

import store from './../../store'

import Radio from './components/radio'
//import Image from './components/image'
//import Video from './components/video'
import SmallCards from './components/tapes/small-cards'
import Footer from './components/footer'

import useScrollToTop from './../../lib/use-scroll-to-top'
import useCastumStyle from './../../lib/use-castum-style'

import setHead from 'generatamine/libs/setHead'
import header from './../../headers/lenta'

import projectsIcon from './../../assets/icons/projects.png'
import servicesIcon from './../../assets/icons/service.png'

import mainItems from './../../main-items.js'

const otherItems = [{
  image: projectsIcon,
  title: 'Мои проекты',
  description: 'Отвечая на частый вопрос: Я не всегда зарабатываю ...',
  link: ['/projects', 'Открыть'],
}, {
  image: servicesIcon,
  title: 'Мои сервисы',
  description: 'Очень жаль что время стирает память и части ...',
  link: ['/services', 'Открыть'],
}, ...mainItems]

const Lenta = observer(() => {
  useScrollToTop()
  useCastumStyle()

  useEffect(() => {
    setHead(
      header()
    )
  }, [])

  const styles = store.theme === 'light'
                  ? lightStyles
                  : darkStyles

  const scrollY = useScrollPosition(10)

  const [followMouse, setFollowMouse] = useState(false)
  const [mouse, setMouse] = useState({ x: 0, y: 0 })

  useEffect(() => {
    if (scrollY > 200) {
      setFollowMouse(true)
    } else {
      setFollowMouse(false)
    }
  }, [scrollY])

  useEffect(() => {
    let timeid = null

    const handler = (e) => {
      clearTimeout(timeid)
      timeid = setTimeout(() => {
        setMouse({ x: e.pageX, y: e.pageY })
      }, 500)
    }

    window.addEventListener('mousemove', handler)
    window.addEventListener('scroll', handler)
    return () => {
      window.removeEventListener('scroll', handler)
      window.removeEventListener('mousemove', handler)
    }
  }, [])

  return (
    <div className={styles.wrapper}>
      {
        <Player
          autoplay
          loop
          mode='normal'
          speed='1'
          src='/api/media/sticker.json'
          style={{
            position: 'relative',
            zIndex: 99999999999,
            padding: '40px',
            width: '12vw',
            minWidth: '140px',
            height: '12vw',
            minHeight: '140px',
            transition: followMouse ? `3s` : `0.8s`,
            transform: (isBrowser && followMouse) ? `translate(${mouse.x - 500}px, ${mouse.y}px) scale(0.8)` : 'translate(0px, 0px) scale(1)'
          }}
        />
      }
      <h1 className={styles.titleH1}>Лента новостей</h1>
      <p className={styles.description}>Заметки, новости, анонсы, мемы, важные и не только (только).</p>
      {/*
        <h2 className={styles.titleH2} name='uran'>Атмосфера не творческая</h2>
        <p className={styles.description}>Сегодня <b>13 апреля 2022 года</b>. Уже <b>полтора месяца</b> мне ужасно тяжело работать и создавать что-либо и в принципе верить во что-то, уверенность в завтрашнем дне - помним, любим, скорбим. Вся эта история очень большой стресс для меня, для человека который в принципе не нервничает и не паникует. Наверно потому что совладать с «этим» я не представляю как, все равно что не возможно. Как можно наблюдать многие <selection>IT деятели</selection> покинули дефолтное местоположние и я их понимаю.</p>
        <p className={styles.quote}>Атмосфера не творческая, атмосфера как гэбня — атмосфера кровавая.</p>
        <p className={styles.description}>К сжалению <b>мой муд:</b></p>
        <Video src='/api/video/asdasdasdasdasdasdadsedwedw232.mp4' />
        <h2 className={styles.titleH2} name='uran'>Уран</h2>
        <p className={styles.description}>В связи со всеми <u><b>ужасными преступлениями против человечества</b></u>. Я объявляю что <b>90%</b> денег от продажи этой скульпутуры пойдут на поддержку армии планеты Уран. На момент <b>8 апреля 2022 года</b> объявляю цену в <selection>3500$</selection>. Связаться со мной можно в <a href='https://t.me/prohetamine'>телеграм</a> или другой <a href='/#social'>соц сети</a>. Отправлю в любой город или страну.</p>
        <Image alt='Уран' src='/api/images/1638256412121957268.jpg' />
      */}
      <h2 className={styles.titleH2} name='radio-ether'>День радио</h2>
      <p className={styles.description}>Наконец-то это случилось, сегодня <b>5 апреля 2022 года</b> радио заиграло на коротких и длинных частотах. Что называется этот день мы приближали как могли, да будет радио! <a href='https://prohetamine.github.io/radio-boomer/'>Radio.Boomer FM</a>. О том точном расписании ведения прямых эфиров пока не известно ¯\_(ツ)_/¯</p>
      <Radio />
      <h1 className={styles.titleH1}>Что-то ещё</h1>
      <SmallCards items={otherItems} />
      <Footer />
    </div>
  )
})

export default Lenta
