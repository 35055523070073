import React, { useState, useEffect } from 'react'
import { observer } from 'mobx-react-lite'
import queryString from 'query-string'
import { useLocation } from 'react-router-dom'

import mainStyles from './styles/main.module.css'
import lightStyles from './styles/light.module.css'
import darkStyles from './styles/dark.module.css'

const light = { ...mainStyles, ...lightStyles }
    , dark = { ...mainStyles, ...darkStyles }

import store from './../../store'

import Cards from './components/tapes/cards'
import Filter from './components/filter'
import SmallCards from './components/tapes/small-cards'
import Footer from './components/footer'

import useScrollToTop from './../../lib/use-scroll-to-top'
import useCastumStyle from './../../lib/use-castum-style'

import setHead from 'generatamine/libs/setHead'
import header from './../../headers/services'

import projectsIcon from './../../assets/icons/projects.png'
import merchIcon from './../../assets/icons/merch.png'

import mainItems from './../../main-items.js'

const otherItems = [{
  image: projectsIcon,
  title: 'Мои проекты',
  description: 'Отвечая на частый вопрос: Я не всегда зарабатываю ...',
  link: ['/projects', 'Открыть'],
}, {
  image: merchIcon,
  title: 'Мерч',
  description: 'Мой мерч — самый уникальный мерч.',
  link: ['/merch', 'Открыть'],
}, ...mainItems]

const Serivces = observer(() => {
  useScrollToTop()
  useCastumStyle()

  useEffect(() => {
    setHead(
      header()
    )
  }, [])

  const styles = store.theme === 'light'
                  ? light
                  : dark

  const location = useLocation()
  const query = queryString.parse(location.search)
  const [tag, setTag] = useState(query.tag || 'Все')

  const items = store.api.services || []

  const tags = Object.keys(
    items.map(({ tags }) =>
      tags.map(tags => tags)
    ).flat().reduce((ctx, tag) => {
      ctx[tag] = ''
      return ctx
    }, {})
  )

  const _items = items.filter(
    item =>
      tag !== 'Все'
        ? item.tags.find(_tag => _tag === tag)
        : true
   )

  return (
    <div className={styles.wrapper}>
      <h1 className={styles.titleH1 + ' ' + styles.titleMarginTop}>Мои сервисы</h1>
      <p className={styles.description} dangerouslySetInnerHTML={{ __html: 'Очень жаль что время стирает память и части истории становятся уже ничем, как будто их никогда и не было. Тут будут сервисы которые жили когда-то или живут и сейчас, а также те которые по тем или иным причинам я не запустил или только планирую в обозримом будущем и вообщем <selection>мои</selection> самые популярные идеи.' }} />
      <Filter tags={tags} activeTag={tag} onTag={tag => setTag(tag)} />
      <Cards items={_items} path='/service' />
      <h1 className={styles.titleH1}>Что-то ещё</h1>
      <SmallCards items={otherItems} />
      <Footer />
    </div>
  )
})

export default Serivces
