import React, { useEffect } from 'react'
import { observer } from 'mobx-react-lite'
import lightStyles from './light.module.css'
import darkStyles from './dark.module.css'

import store from './store'

import Menu from './components/menu'
import Pages from './components/pages'
import Loader from './components/loader'
import Error from './components/error'

import setHead from 'generatamine/libs/setHead'
import useNormalizeRouterPath from 'generatamine/libs/react-router-dom-v6'
import header from './headers/default'

const App = observer(() => {
  const styles = store.theme === 'light'
                  ? lightStyles
                  : darkStyles

  useEffect(() => {
    setHead(
      header()
    )
  }, [])

  useNormalizeRouterPath()

  useEffect(() => {
    [
      ['achievements', '/api/achievements.json'],
      //['historySound', '/api/history-sound.json'],
      ['services', '/api/services.json'],
      ['projects', '/api/projects.json'],
      ['articles', '/api/articles.json'],
      ['merch', '/api/merch.json']
    ].forEach(([key, url]) =>
      fetch(url)
        .then(content => content.json())
        .then(items => store.api[key] = items)
    )
  }, [])

  return (
    <Error>
      <div className={styles.body}>
        <Menu />
        <Pages />
        <Loader />
      </div>
    </Error>
  )
})

export default App
