import { useEffect } from 'react'
import store from './../store'
import { useLocation } from 'react-router-dom'

const style = document.querySelector('style[class="default-style"]')

const useCastumStyle = () => {
  const location = useLocation()

  useEffect(() => {
    style.textContent = `
        body,html {
          background-color: ${store.load ? store.theme === 'light' ? '#fafafa' : '#0c0c0c' : '#F3DC1E'};
          margin: 0;
          font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
            'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
            sans-serif;
          -webkit-font-smoothing: antialiased;
          -moz-osx-font-smoothing: grayscale;
          overflow-x: hidden;
          overflow-y: ${store.load ? 'auto' : 'hidden'};
        }

        input[type=text],textarea {
          -webkit-appearance: none;
          -moz-appearance: none;
          appearance: none;
          outline: none;
        }

        a {
          color: ${store.theme === 'light' ? '#4a4a4a' : '#d2d2d2'}
        }

        selection {
          padding: 2px 0px;
          max-width: 900px;
          font-family: Roboto;
          font-style: normal;
          font-weight: normal;
          font-size: 17px;
          line-height: 25px;
          letter-spacing: 0.1px;
          margin-bottom: 9px;
          margin-top: 0px;
          background: #f3dc1e;
          color: ${store.theme === 'light' ? '#303030' : '#0B0B0B'};
        }

        @media (max-width: 640px) {
          selection {
            letter-spacing: 0px;
            font-size: 16px;
            line-height: 22px;
            margin-bottom: 12px;
            margin-top: 4px;
          }
        }

        input[type=text]::placeholder{ color: #d69ccd; }
        textarea::placeholder{ color: #d69ccd; }

        ::selection {
          background: #F3DC1E;
          color: ${
            store.load
              ? store.theme === 'light' ? '#0B0B0B' : '#0B0B0B'
              : '#F3DC1E'
          };
          text-shadow: none;
        }

        .pink-text-selected::selection {
          background: #ffc8f6;
          color: #0B0B0B;
        }

        .console-text-selected::selection {
          background: #56ab4861;
          color: #b2d5ac;
        }

        code {
          font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
          monospace;
        }

        ::scrollbar {
          width: 6px;
          height: 6px;
        }

        ::-moz-scrollbar {
          width: 6px;
          height: 6px;
        }

        ::-webkit-scrollbar {
          width: 6px;
          height: 6px;
        }

        ::scrollbar-corner { background-color: transparent; }
        ::-moz-scrollbar-corner { background-color: transparent; }
        ::-webkit-scrollbar-corner { background-color: transparent; }

        ::scrollbar-thumb {
          height: 20px;
          background-color: #77777769;
          border-radius: 6px;
        }

        ::-moz-scrollbar-thumb {
          height: 20px;
          background-color: #77777769;
          border-radius: 6px;
        }

        ::-webkit-scrollbar-thumb {
          height: 20px;
          background-color: #77777769;
          border-radius: 6px;
        }

        ::scrollbar-button {
          display: none;
        }

        ::-moz-scrollbar-button {
          display: none;
        }

        ::-webkit-scrollbar-button {
          display: none;
        }

        ::scrollbar-button {
          display: none;
        }

        body::scrollbar {
          width: 20px;
        }

        body::-moz-scrollbar {
          width: 20px;
        }

        body::-webkit-scrollbar {
          width: 20px;
        }

        body::scrollbar-thumb {
          height: 100px;
          background-color: ${
            store.load
              ? store.theme === 'light'
                  ? '#0c0c0c'
                  : '#e8e8e8'
              : '#F3DC1E'
          };
          border-radius: 16px;
          border: 4px solid ${
            store.load
              ? store.theme === 'light'
                  ? '#fafafa'
                  : '#0c0c0c'
              : '#F3DC1E'
          };
        }

        body::-moz-scrollbar-thumb {
          height: 100px;
          background-color: ${
            store.load
              ? store.theme === 'light'
                  ? '#0c0c0c'
                  : '#e8e8e8'
              : '#F3DC1E'
          };
          border-radius: 16px;
          border: 4px solid ${
            store.load
              ? store.theme === 'light'
                  ? '#fafafa'
                  : '#0c0c0c'
              : '#F3DC1E'
          };
        }

        body::-webkit-scrollbar-thumb {
          height: 100px;
          background-color: ${
            store.load
              ? store.theme === 'light'
                  ? '#0c0c0c'
                  : '#e8e8e8'
              : '#F3DC1E'
          };
          border-radius: 16px;
          border: 4px solid ${
            store.load
              ? store.theme === 'light'
                  ? '#fafafa'
                  : '#0c0c0c'
              : '#F3DC1E'
          };
        }

        body::scrollbar-button {
          display: none;
        }

        body::-moz-scrollbar-button {
          display: none;
        }

        body::-webkit-scrollbar-button {
          display: none;
        }
    `
  }, [store.load, store.theme, location.key])
}

export default useCastumStyle
