const Merch = () => {
  const author = 'Stas Prohetamine'
      , title = `${author} | Мерч`
      , description = 'Покупая мой мерч вы делаете своего рода инвестицию в мои будущие проекты тем самым помогаете мне разививать мое дело, в тоже время покупаете часть моей истории которой я очень дорожу. Мой мерч это хорошая возможность приобрести что-то уникальное прямо из рук мастера. Вероятно большенство из моего мерча будет производиться в единичном варианте.'

  return ({
    title: { tag: 'title', attributes: { innerHTML: title }},
    og_title: { tag: 'meta', attributes: { property: 'og:title', content: title }},
    author: { tag: 'meta', attributes: { name: 'author', content: author }},
    description: { tag: 'meta', attributes: { name: 'description', content: description }},
    og_description: { tag: 'meta', attributes: { property: 'og:description', content: description }},
    keywords: { tag: 'meta', attributes: { name: 'keywords', content: title }},
  })
}

export default Merch
