import React, { useState, useEffect } from 'react'
import queryString from 'query-string'
import { useLocation } from 'react-router-dom'
import { observer } from 'mobx-react-lite'

import lightStyles from './styles/light.module.css'
import darkStyles from './styles/dark.module.css'

import store from './../../store'

import MiddleCards from './components/tapes/middle-cards'
import Filter from './components/filter'
import SmallCards from './components/tapes/small-cards'
import Footer from './components/footer'

import useScrollToTop from './../../lib/use-scroll-to-top'
import useCastumStyle from './../../lib/use-castum-style'

import setHead from 'generatamine/libs/setHead'
import header from './../../headers/merch'

import projectsIcon from './../../assets/icons/projects.png'
import servicesIcon from './../../assets/icons/service.png'

import mainItems from './../../main-items.js'

const otherItems = [{
  image: projectsIcon,
  title: 'Мои проекты',
  description: 'Отвечая на частый вопрос: Я не всегда зарабатываю ...',
  link: ['/projects', 'Открыть'],
}, {
  image: servicesIcon,
  title: 'Мои сервисы',
  description: 'Очень жаль что время стирает память и части ...',
  link: ['/services', 'Открыть'],
}, ...mainItems]

const Merch = observer(() => {
  useScrollToTop()
  useCastumStyle()

  useEffect(() => {
    setHead(
      header()
    )
  }, [])

  const styles = store.theme === 'light'
                  ? lightStyles
                  : darkStyles

  const location = useLocation()
  const query = queryString.parse(location.search)
  const [tag, setTag] = useState(query.tag || 'Все')

  const items = store.api.merch || []

  const tags = Object.keys(
    items.map(({ tags }) =>
      tags.map(tags => tags)
    ).flat().reduce((ctx, tag) => {
      ctx[tag] = ''
      return ctx
    }, {})
  )

  const _items = items.filter(item => tag !== 'Все' ? item.tags.find(_tag => _tag === tag) : true)

  return (
    <div className={styles.wrapper}>
      <h1 className={styles.titleH1 + ' ' + styles.titleMarginTop}>Мерч</h1>
      <p className={styles.description}>Покупая мой мерч вы делаете своего рода инвестицию в <b>мои будущие проекты</b> тем самым помогаете мне разививать мое дело, в тоже время покупаете <b>часть моей истории которой я очень дорожу</b>. Мой мерч это хорошая возможность приобрести что-то уникальное прямо из рук мастера. Вероятно большенство из моего мерча будет производиться в единичном варианте.</p>
      <Filter tags={tags} activeTag={tag} onTag={tag => setTag(tag)} />
      <MiddleCards items={_items} target='_blank' />
      <h1 className={styles.titleH1}>Что-то ещё</h1>
      <SmallCards items={otherItems} />
      <Footer />
    </div>
  )
})

export default Merch
