import React from 'react'
import { observer } from 'mobx-react-lite'
import { useNavigate } from 'react-router-dom'

import mainStyles from './../../styles/main.module.css'
import lightStyles from './light.module.css'
import darkStyles from './dark.module.css'
import globalLightStyles from './../../styles/light.module.css'
import globalDarkStyles from './../../styles/dark.module.css'

const dark = { ...mainStyles, ...globalDarkStyles, ...darkStyles }
    , light = {  ...mainStyles, ...globalLightStyles, ...lightStyles }

import store from './../../../../store'

const Filter = observer(({ tags, activeTag, onTag }) => {
  const styles = store.theme === 'light'
                  ? light
                  : dark

  const navigate = useNavigate()

  return (
    <p className={styles.description}>
      <b>Поиск по тегам:</b>
      {
        ['Все', ...tags].map((tag, key) => (
          <span
            className={styles.tag}
            style={
              activeTag === tag
                ? { }
                : { color: 'inherit', background: '#ffffff00' }
            }
            key={key}
            onClick={() => {
              onTag(tag)
              navigate(`?tag=${tag}`)
            }}
          >
            #{tag}
          </span>
        ))
      }
    </p>
  )
})

export default Filter
