const Services = () => {
  const author = 'Stas Prohetamine'
      , title = `${author} | Мои сервисы`
      , description = 'Очень жаль что время стирает память и части истории становятся уже ничем, как будто их никогда и не было. Тут будут сервисы которые жили когда-то или живут и сейчас, а также те которые по тем или иным причинам я не запустил или только планирую в обозримом будущем и вообщем мои идеи.'

  return ({
    title: { tag: 'title', attributes: { innerHTML: title }},
    og_title: { tag: 'meta', attributes: { property: 'og:title', content: title }},
    author: { tag: 'meta', attributes: { name: 'author', content: author }},
    description: { tag: 'meta', attributes: { name: 'description', content: description }},
    og_description: { tag: 'meta', attributes: { property: 'og:description', content: description }},
    keywords: { tag: 'meta', attributes: { name: 'keywords', content: title }},
  })
}

export default Services
