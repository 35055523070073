import React, { useState, useEffect, useRef } from 'react'
import useWindowWidth from 'use-window-width'
import { Link } from 'react-router-dom'
import { observer } from 'mobx-react-lite'
import { motion } from 'framer-motion'
import md5 from 'md5'
import cyrillicToTranslit from 'cyrillic-to-translit-js'

import mainStyles from './main.module.css'
import lightStyles from './light.module.css'
import darkStyles from './dark.module.css'
import globalLightStyles from './../../../styles/light.module.css'
import globalDarkStyles from './../../../styles/dark.module.css'

const dark = { ...globalDarkStyles, ...mainStyles, ...darkStyles }
    , light = { ...globalLightStyles, ...mainStyles, ...lightStyles }

import store from './../../../../../store'

const Column = observer(({ repeat, dafaultScroll, style, data, path, hash, speed }) => {
  const styles = store.theme === 'light'
                  ? light
                  : dark

  const refScroll = useRef()
      , refHeight = useRef()
      , [loadR, setLoadR] = useState(0)

  useEffect(() => {
    const nodeScroll = refScroll.current
        , nodeHeight = refHeight.current

    if (nodeScroll && nodeHeight) {
      if (!window['s'+hash]) {
        window['s'+hash] = 0
      }

      const height = parseFloat(window.getComputedStyle(nodeHeight).height)

      const stop = (() => {
        let isStop = false

        const handlerAnimation = () => {
          if (window['s'+hash] >= height) {
            window['s'+hash] = 0;
          }

          window['s'+hash] += speed
          nodeScroll.scrollTo(0, window['s'+hash] + dafaultScroll)

          isStop || requestAnimationFrame(handlerAnimation)
        }

        requestAnimationFrame(handlerAnimation)

        return () => {
          isStop = true
        }
      })()

      return () => stop()
    }
  }, [speed, hash, refScroll, refHeight, dafaultScroll, data, loadR])

  return (
    <div
      className={styles.column}
      ref={refScroll}
      style={style}
    >
      {
        Array(repeat).fill(true).map((_, key) =>
          <div key={key} ref={key === 0 ? refHeight : null}>
            {
              data.map(
                ({ image, title, titleShort = '...' }, _key) => (
                  <motion.div
                    className={styles.link}
                    key={_key}
                    initial={{ scale: 1 }}
                    whileHover={{ scale: 0.95 }}
                    whileTap={{ scale: 1 }}
                  >
                    <Link to={`${path}/${cyrillicToTranslit().transform(title.toLowerCase(), '-').replace(/[^\w]/gi, '-')}`}>
                      <img
                        className={styles.linkImg}
                        src={image}
                        onLoad={() => setLoadR(Math.random())}
                        onDragStart={e => e.preventDefault()}
                      />
                      <motion.div
                        className={styles.linkOverflov + ' ' + styles.description}
                        initial={{ opacity: 0 }}
                        whileHover={{ opacity: 1 }}
                        whileTap={{ opacity: 0 }}
                        style={{ fontWeight: 600 }}
                      >
                        {titleShort}
                      </motion.div>
                    </Link>
                  </motion.div>
                )
              )
            }
          </div>
        )
      }
    </div>
  )
})

const MovingCards = observer(({ items, path, repeat }) => {
  const styles = store.theme === 'light'
                  ? light
                  : dark

  const refWidth = useRef()
      , windowWidth = useWindowWidth()
      , [width, setWidth] = useState(900)

  useEffect(() => {
    const node = refWidth.current

    if (node) {
      const handler = () => {
        const { width } = node.getBoundingClientRect()
        setWidth(width)
      }

      handler()
      window.addEventListener('resize', handler)
      return () => window.removeEventListener('resize', handler)
    }
  }, [refWidth, store.isMenu])

  const _width = (width - (windowWidth > 800 ? 60 : 30)) / (windowWidth > 800 ? 5 : 3)

  const rowCount = windowWidth > 800 ? 5 : 3
  const chunkLength = parseInt(items.length / rowCount)

  const data = items.reduce((ctx, elem, key) => {
    if (ctx.length === 0) {
      ctx.push([])
    }

    if (ctx.length === rowCount && ctx[ctx.length - 1].length === chunkLength) {
      ctx[key % ctx.length].push({
        image: elem.image,
        title: elem.title,
        titleShort: elem.titleShort
      })
      return ctx
    }

    if (ctx[ctx.length - 1].length === chunkLength) {
      ctx.push([{
        image: elem.image,
        title: elem.title,
        titleShort: elem.titleShort
      }])
    } else {
      ctx[ctx.length - 1].push({
        image: elem.image,
        title: elem.title,
        titleShort: elem.titleShort
      })
    }

    return ctx
  }, [])

  return (
    <div className={styles.body}>
      <div className={styles.tapeBody}>
        <div className={styles.wrapper} ref={refWidth}>
          {
            data.map((chunk, key) => {
              const hash = md5(chunk[0] ? path+chunk[0].title : path+0)

              return (
                <Column
                  key={key}
                  data={chunk}
                  repeat={repeat}
                  path={path}
                  hash={hash}
                  speed={0.4}
                  style={
                    (key === 0 || data.length - 1 === key)
                      ? {
                        [key === 0 ? 'paddingLeft' : 'paddingRight']: '0px'
                      }
                      : {}
                  }
                  dafaultScroll={
                    key % 2 === 0
                      ? _width / 2
                      : 0
                  }
                />
              )
            })
          }
        </div>
      </div>
    </div>
  )
})

export default MovingCards
