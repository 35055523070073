import React, { useEffect } from 'react'
import { useParams } from 'react-router-dom'
import { observer } from 'mobx-react-lite'
import cyrillicToTranslit from 'cyrillic-to-translit-js'

import mainStyles from './styles/main.module.css'
import lightStyles from './styles/light.module.css'
import darkStyles from './styles/dark.module.css'

const light = { ...mainStyles, ...lightStyles }
    , dark = { ...mainStyles, ...darkStyles }

import store from './../../store'

import ButtonLink from './components/button-link'
import CreateArticle from './components/create-article'
import SmallCards from './components/tapes/small-cards'
import Tags from './components/tags'
import Footer from './components/footer'

import useScrollToTop from './../../lib/use-scroll-to-top'
import useCastumStyle from './../../lib/use-castum-style'
import useArticleNavigation from './../../lib/use-article-navigation'

import setHead from 'generatamine/libs/setHead'
import header from './../../headers/service'

const Service = observer(() => {
  useScrollToTop()
  useCastumStyle()

  const styles = store.theme === 'light'
                  ? light
                  : dark

  const { title: _title } = useParams()

  const items = store.api.services || []

  const index = items.findIndex(({ title }) => cyrillicToTranslit().transform(title.toLowerCase(), '-').replace(/[^\w]/gi, '-') === _title)

  const isFindService = !!items[index] && items[index].html && items[index].title
      , isLoad = items.length > 0

  useEffect(() => {
    setHead(
      header(
        isLoad
          ? isFindService
              ? ({
                title: items[index].html.find(([type]) => type === 'h1')[1],
                description: items[index].html.find(([type]) => type === 'p')[1],
                type: items[index].type
              })
              : ({
                title: '404',
                description: 'Сервис не найден или еще не оформлен должным образом и его не возможно отобразить, может быть он удален или его никогда не было ? Посмотрите в разделе со всеми сервисами, может быть просто изменилась ссылка..'
              })
          : ({
            type: 'Загрузка',
            title: 'Загрузка',
            description: 'Загрузка'
          })
      )
    )
  }, [isLoad, isFindService])

  const navigate = useArticleNavigation(items, index, '/service')

  return isLoad
            ? isFindService
                ? (
                  <div className={styles.wrapper}>
                    <CreateArticle codeHtml={items[index].html} />
                    <Tags path='/services' tags={items[index].tags} />
                    <h1 className={styles.titleH1}>Другие сервисы</h1>
                    <SmallCards items={navigate} />
                    <Footer />
                  </div>
                )
                : (
                  <div className={styles.wrapper}>
                    <h1 className={styles.titleH1 + ' ' + styles.titleMarginTop}>404</h1>
                    <p className={styles.description}>Сервис не найден или еще не оформлен должным образом и его не возможно отобразить, может быть он удален или его никогда не было ? Посмотрите в разделе со всеми сервисами, может быть просто изменилась ссылка..</p>
                    <ButtonLink to='/services'>Смотреть все сервисы</ButtonLink>
                  </div>
                )
            : (
              <div className={styles.wrapper}>
                <h1 className={styles.titleH1 + ' ' + styles.titleMarginTop}>Загрузка...</h1>
                <p className={styles.description}>Подождите немного и тут будет много букв.</p>
              </div>
            )
})

export default Service
