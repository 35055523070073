import React from 'react'
import { motion } from 'framer-motion'
import useWindowWidth from 'use-window-width'
import { observer } from 'mobx-react-lite'

import lightStyles from './light.module.css'
import darkStyles from './dark.module.css'

import store from './../../../store'

const ThemeButton = observer(() => {
  const styles = store.theme === 'light'
                  ? lightStyles
                  : darkStyles

  const width = useWindowWidth()

  return (
    <div
      className={styles.body}
      onClick={() => {
        store.theme = store.theme === 'light' ? 'dark' : 'light'
      }}
    >
      <div className={styles.text}>{store.theme === 'light' ? 'Светлая' : 'Темная'} версия сайта</div>
      <div className={styles.overflow}>
        <motion.div
          className={styles.circle}
          animate={
            store.theme === 'light'
              ? { left: width >= 640 ? '8px' : '4px' }
              : { left: width >= 640 ? '35px' : '17px' }
          }
          transition={{ duration: 0.2 }}
        />
      </div>
    </div>
  )
})

export default ThemeButton
