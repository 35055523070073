import React, { useEffect } from 'react'
import { observer } from 'mobx-react-lite'

import mainStyles from './styles/main.module.css'
import lightStyles from './styles/light.module.css'
import darkStyles from './styles/dark.module.css'

const light = { ...mainStyles, ...lightStyles }
    , dark = { ...mainStyles, ...darkStyles }

import store from './../../store'

import NumberedText from './components/tapes/numbered-text'
import SmallCards from './components/tapes/small-cards'
import Footer from './components/footer'

import useScrollToTop from './../../lib/use-scroll-to-top'
import useCastumStyle from './../../lib/use-castum-style'

import setHead from 'generatamine/libs/setHead'
import header from './../../headers/achievements'

import historyIcon from './../../assets/icons/history.png'
import articlesIcon from './../../assets/icons/articles.png'

import mainItems from './../../main-items.js'

const otherItems = [{
  image: historyIcon,
  title: 'Охуительные истории',
  description: 'Тут я публикую истории, голосовая хронология так сказать ...',
  link: ['/history', 'Открыть'],
}, {
  image: articlesIcon,
  title: 'Статьи',
  description: 'Пишу о своем деле. О идеях и проектах, некоторые из моих ...',
  link: ['/articles', 'Открыть'],
}, ...mainItems]

const Achievements = observer(() => {
  useScrollToTop()
  useCastumStyle()

  useEffect(() => {
    setHead(
      header()
    )
  }, [])


  const styles = store.theme === 'light'
                  ? light
                  : dark

  return (
    <div className={styles.wrapper}>
      <h1 className={styles.titleH1 + ' ' + styles.titleMarginTop}>Мои достижения</h1>
      <p className={styles.description}>То чем я так или наче горжусь или с ухмылкой вспоминаю. <b>Дисклеймер</b> согласно описанию блока с <a className={styles.link} href="/#history">охуительными историями</a> также всему верить не стоит, некоторая часть является выдумкой и все совпадения случайны, код на который я ссылаюсь был занесен мне в репозитории неизвестным вирусом и не удаляется.</p>
      <NumberedText items={store.api.achievements || []} />
      <h1 className={styles.titleH1}>Что-то ещё</h1>
      <SmallCards items={otherItems} />
      <Footer />
    </div>
  )
})

export default Achievements
