import React, { useState, useEffect } from 'react'
import queryString from 'query-string'
import { useLocation } from 'react-router-dom'
import { observer } from 'mobx-react-lite'

import mainStyles from './styles/main.module.css'
import lightStyles from './styles/light.module.css'
import darkStyles from './styles/dark.module.css'

const light = { ...mainStyles, ...lightStyles }
    , dark = { ...mainStyles, ...darkStyles }

import store from './../../store'

import MaxCards from './components/tapes/max-cards'
import Filter from './components/filter'
import SmallCards from './components/tapes/small-cards'
import Footer from './components/footer'

import useScrollToTop from './../../lib/use-scroll-to-top'
import useCastumStyle from './../../lib/use-castum-style'

import setHead from 'generatamine/libs/setHead'
import header from './../../headers/articles'

import historyIcon from './../../assets/icons/history.png'
import myProgressIcon from './../../assets/icons/my_progress.png'

import mainItems from './../../main-items.js'

const otherItems = [{
  image: historyIcon,
  title: 'Охуительные истории',
  description: 'Тут я публикую истории, голосовая хронология так сказать ...',
  link: ['/history', 'Открыть'],
}, {
  image: myProgressIcon,
  title: 'Мои достижения',
  description: 'То чем я так или наче горжусь или с ухмылкой вспоминаю. ...',
  link: ['/achievements', 'Открыть'],
}, ...mainItems]

const Articles = observer(() => {
  useScrollToTop()
  useCastumStyle()

  useEffect(() => {
    setHead(
      header()
    )
  }, [])

  const styles = store.theme === 'light'
                  ? light
                  : dark

  const location = useLocation()
  const query = queryString.parse(location.search)
  const [tag, setTag] = useState(query.tag || 'Все')

  const items = store.api.articles || []

  const tags = Object.keys(
    items.map(({ tags }) =>
      tags.map(tags => tags)
    ).flat().reduce((ctx, tag) => {
      ctx[tag] = ''
      return ctx
    }, {})
  )

  const _items = items.filter(item => tag !== 'Все' ? item.tags.find(_tag => _tag === tag) : true)

  return (
    <div className={styles.wrapper}>
      <h1 className={styles.titleH1 + ' ' + styles.titleMarginTop}>Статьи</h1>
        <p className={styles.description}>Пишу о своем деле. О идеях и проектах, некоторые из моих статей призваны быть <b>руководством к действию</b>, а какие-то могут быть и <b>провокацией</b>. Вообще писать статьи — это очень тяжело и трудно, мне не платят, я делаю это просто потому что это важно.</p>
        <p className={styles.quote}>«Знаете, интернет научил меня всему, что я знаю и даже больше, чем просто всему. Интернет стал не просто учением, в котором тяжело, но и боем, в котором легко. И я благодарен всем, кто так или иначе принял участие в моем обучении, через статьи, описание каких-то технологий, видео на YouTube и просто критику моих работ. Это герои моего времени, только благодаря им я сейчас являюсь неплохим специалистом. Ведь я не учился в этих ваших институтах и образований не получал, да и всего у меня 9 классов. Спасибо тем, кто пишет интернет.» — Вступление к статье <b><a href='https://habr.com/ru/post/593421/'>“Электроника всем начинающим”</a></b></p>
      <Filter tags={tags} activeTag={tag} onTag={tag => setTag(tag)} />
      <MaxCards items={_items} target='_blank' />
      <h1 className={styles.titleH1}>Что-то ещё</h1>
      <SmallCards items={otherItems} />
      <Footer />
    </div>
  )
})

export default Articles
