import { observable, observe, toJS } from 'mobx'

let stateDefault = {
  theme: window.matchMedia && window.matchMedia('(prefers-color-scheme: dark)').matches ? 'dark' : 'light',
  load: false,
  isMenu: true,
  api: {}
}

try {
  stateDefault = JSON.parse(localStorage.state)
} catch (e) { /* */ }

const store = observable({
  ...stateDefault,
  load: false
})

observe(store, () => {
  localStorage.state = JSON.stringify(toJS(store))
})

export default store
