import React, { useState, useEffect } from 'react'
import { observer } from 'mobx-react-lite'
import { motion } from 'framer-motion'
import { Link } from 'react-router-dom'
import { isBrowser } from 'react-device-detect'
import Media from 'react-media'
import styled from 'styled-components'

import mainStyles from './main.module.css'
import lightStyles from './light.module.css'
import darkStyles from './dark.module.css'
import globalLightStyles from './../../../styles/light.module.css'
import globalDarkStyles from './../../../styles/dark.module.css'

const dark = { ...globalDarkStyles, ...mainStyles, ...darkStyles }
    , light = { ...globalLightStyles, ...mainStyles, ...lightStyles }

import store from './../../../../../store'

const PureLink = styled.a``

const MaxCards = observer(({ items, target = '_self' }) => {
  const styles = store.theme === 'light'
                  ? light
                  : dark

  const [_items, setItems] = useState(
    items.map(item => ({ ...item, hover: false }))
  )

  useEffect(() => {
    setItems(
      items.map(item => ({ ...item, hover: false }))
    )
  }, [items.map(e => e.title).join('')])

  const CastumLink = target === '_blank' ? PureLink : Link

  return (
    <div className={styles.body}>
      <div>
        {
          _items.map(({ image, description, title, link, hover }, key) =>
            <CastumLink
              target={target}
              rel='noreferrer'
              className={styles.tapeBody}
              key={key}
              to={link[0]}
              href={link[0]}
              style={
                key === 0
                  ? {
                    marginTop: '0px',
                    marginBottom: '20px',
                  }
                  : {
                    marginTop: '0px',
                    marginBottom: key === items.length - 1 ? '0px' : '20px',
                  }
              }
            >
              <Media queries={{
                small: "(max-width: 938px)"
              }}>
                {({ small }) => (
                  <>
                    <div
                      className={styles.image}
                      style={{
                        backgroundImage: `url(${image})`,
                        backgroundSize: 'cover',
                        backgroundPosition: small ? 'center 20%' : 'center center'
                      }}
                    >
                      {
                        small
                          ? (
                            null
                          )
                          : (
                            <div className={styles.overflow}>
                              <div
                                className={styles.description + ' ' + styles.articleDescription}
                              >
                                <h2 className={styles.titleH2} style={{ marginTop: '20px' }}>{title}</h2>
                                <p className={styles.description}>{description}</p>
                              </div>
                              <div
                                className={styles.bodyLink}
                                onMouseOut={
                                  () =>
                                    isBrowser && setItems(
                                      items =>
                                        items.map(item => ({ ...item, hover: false }))
                                    )
                                }
                                onMouseOver={
                                  () =>
                                    isBrowser && setItems(
                                      items =>
                                        items.map(
                                          item => ({
                                            ...item,
                                            hover: title === item.title
                                          })
                                        )
                                    )
                                }
                              >
                              {
                                target === '_self'
                                  ? (
                                    <>
                                      <div className={styles.buttonLink}><b>{link[1]}</b></div>
                                      <motion.svg
                                        animate={
                                          hover
                                            ? {
                                              transform: [`translateX(0px)`, `translateX(3px)`, `translateX(0px)`, `translateX(3px)`]
                                            }
                                            : {
                                              transform: `translateX(0px)`
                                            }
                                        }
                                        className={styles.icon}
                                        style={{
                                          width: '15px',
                                          height: '14px',
                                          marginLeft: '6px'
                                        }}
                                        width='16'
                                        height='12'
                                        viewBox='0 0 16 12'
                                        fill='none'
                                        xmlns='http://www.w3.org/2000/svg'
                                      >
                                        <path d='M14.5303 6.52986C14.8232 6.23697 14.8232 5.76209 14.5303 5.4692L9.7574 0.696232C9.4645 0.403339 8.9896 0.403339 8.6967 0.696232C8.4038 0.989126 8.4038 1.464 8.6967 1.75689L12.9393 5.99953L8.6967 10.2421C8.4038 10.535 8.4038 11.0099 8.6967 11.3028C8.9896 11.5957 9.4645 11.5957 9.7574 11.3028L14.5303 6.52986ZM-2 6.74953H14V5.24953H-2V6.74953Z' className={styles.buttonLink} strokeWidth='0.6' />
                                      </motion.svg>
                                    </>
                                  )
                                  : (
                                    <>
                                      <motion.svg
                                        className={styles.icon}
                                        animate={
                                          hover
                                            ? {
                                              opacity: [1, 0, 1]
                                            }
                                            : {
                                              opacity: 1
                                            }
                                        }
                                        width='16'
                                        height='16'
                                        viewBox='0 0 16 16'
                                        fill='none'
                                        xmlns='http://www.w3.org/2000/svg'
                                      >
                                        <path
                                          className={styles.buttonLink}
                                          strokeWidth='0.6'
                                          fillRule="evenodd"
                                          d="M7.775 3.275a.75.75 0 001.06 1.06l1.25-1.25a2 2 0 112.83 2.83l-2.5 2.5a2 2 0 01-2.83 0 .75.75 0 00-1.06 1.06 3.5 3.5 0 004.95 0l2.5-2.5a3.5 3.5 0 00-4.95-4.95l-1.25 1.25zm-4.69 9.64a2 2 0 010-2.83l2.5-2.5a2 2 0 012.83 0 .75.75 0 001.06-1.06 3.5 3.5 0 00-4.95 0l-2.5 2.5a3.5 3.5 0 004.95 4.95l1.25-1.25a.75.75 0 00-1.06-1.06l-1.25 1.25a2 2 0 01-2.83 0z">
                                        </path>
                                      </motion.svg>
                                      <div className={styles.buttonLink}><b>{link[1]}</b></div>
                                    </>
                                  )
                              }
                              </div>
                            </div>
                          )
                      }
                    </div>
                    {
                      small
                        ? (
                          <div className={styles.overflow}>
                            <div
                              className={styles.description + ' ' + styles.articleDescription}
                            >
                              <h2 className={styles.titleH2} style={{ marginTop: '0px' }}>{title}</h2>
                              <p className={styles.description}>{description}</p>
                            </div>
                            <div
                              className={styles.bodyLink}
                              onMouseOut={
                                () =>
                                  isBrowser && setItems(
                                    items =>
                                      items.map(item => ({ ...item, hover: false }))
                                  )
                              }
                              onMouseOver={
                                () =>
                                  isBrowser && setItems(
                                    items =>
                                      items.map(
                                        item => ({
                                          ...item,
                                          hover: title === item.title
                                        })
                                      )
                                  )
                              }
                            >
                            {
                              target === '_self'
                                ? (
                                  <>
                                    <div className={styles.buttonLink}><b>{link[1]}</b></div>
                                    <motion.svg
                                      animate={
                                        hover
                                          ? {
                                            transform: [`translateX(0px)`, `translateX(3px)`, `translateX(0px)`, `translateX(3px)`]
                                          }
                                          : {
                                            transform: `translateX(0px)`
                                          }
                                      }
                                      className={styles.icon}
                                      style={{
                                        width: '15px',
                                        height: '14px',
                                        marginLeft: '6px'
                                      }}
                                      width='16'
                                      height='12'
                                      viewBox='0 0 16 12'
                                      fill='none'
                                      xmlns='http://www.w3.org/2000/svg'
                                    >
                                      <path d='M14.5303 6.52986C14.8232 6.23697 14.8232 5.76209 14.5303 5.4692L9.7574 0.696232C9.4645 0.403339 8.9896 0.403339 8.6967 0.696232C8.4038 0.989126 8.4038 1.464 8.6967 1.75689L12.9393 5.99953L8.6967 10.2421C8.4038 10.535 8.4038 11.0099 8.6967 11.3028C8.9896 11.5957 9.4645 11.5957 9.7574 11.3028L14.5303 6.52986ZM-2 6.74953H14V5.24953H-2V6.74953Z' className={styles.buttonLink} strokeWidth='0.6' />
                                    </motion.svg>
                                  </>
                                )
                                : (
                                  <>
                                    <motion.svg
                                      className={styles.icon}
                                      animate={
                                        hover
                                          ? {
                                            opacity: [1, 0, 1]
                                          }
                                          : {
                                            opacity: 1
                                          }
                                      }
                                      width='16'
                                      height='16'
                                      viewBox='0 0 16 16'
                                      fill='none'
                                      xmlns='http://www.w3.org/2000/svg'
                                    >
                                      <path
                                        className={styles.buttonLink}
                                        strokeWidth='0.6'
                                        fillRule="evenodd"
                                        d="M7.775 3.275a.75.75 0 001.06 1.06l1.25-1.25a2 2 0 112.83 2.83l-2.5 2.5a2 2 0 01-2.83 0 .75.75 0 00-1.06 1.06 3.5 3.5 0 004.95 0l2.5-2.5a3.5 3.5 0 00-4.95-4.95l-1.25 1.25zm-4.69 9.64a2 2 0 010-2.83l2.5-2.5a2 2 0 012.83 0 .75.75 0 001.06-1.06 3.5 3.5 0 00-4.95 0l-2.5 2.5a3.5 3.5 0 004.95 4.95l1.25-1.25a.75.75 0 00-1.06-1.06l-1.25 1.25a2 2 0 01-2.83 0z">
                                      </path>
                                    </motion.svg>
                                    <div className={styles.buttonLink}><b>{link[1]}</b></div>
                                  </>
                                )
                            }
                            </div>
                          </div>
                        )
                        : (
                          null
                        )
                    }
                  </>
                )}
              </Media>
            </CastumLink>
          )
        }
      </div>
    </div>
  )
})

export default MaxCards
