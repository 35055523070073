import React, { useState } from 'react'
import { observer } from 'mobx-react-lite'
import { motion } from 'framer-motion'
import { isBrowser } from 'react-device-detect'

import mainStyles from './main.module.css'
import lightStyles from './light.module.css'
import darkStyles from './dark.module.css'
import globalLightStyles from './../../styles/light.module.css'
import globalDarkStyles from './../../styles/dark.module.css'

const dark = { ...globalDarkStyles, ...mainStyles, ...darkStyles }
    , light = { ...globalLightStyles, ...mainStyles, ...lightStyles }

import store from './../../../../store'

const SocialTape = observer(() => {
  const styles = store.theme === 'light'
                  ? light
                  : dark

  const [blured, setBlured] = useState(
    Array(8).fill(false)
  )

  return (
    <div className={styles.body}>
      <div className={styles.wrapper}>
        <motion.div
          className={styles.tapeBody}
          onTapStart={
            () =>
              isBrowser && setBlured(
                items =>
                  items.map(
                    (item, _key) =>
                      0 === _key
                        ? false
                        : true
                  )
              )
          }
          onTap={
            () =>
              isBrowser && setBlured(
                items =>
                  items.map(() => false)
              )
          }
          onHoverEnd={
            () =>
              isBrowser && setBlured(
                items =>
                  items.map(() => false)
              )
          }
          animate={{ filter: `blur(${blured[0] ? 10 : 0}px)`, zIndex: 99999 }}
          transition={{ duration: 0.2, delay: blured[0] ? 0.2 : 0 }}
        >
          <div className={styles.overflow}>
            <div className={styles.sectionTitle}>Основные контакты</div>
            <span className={styles.overflowText} style={{ color: '#0088cc', marginTop: '2px' }}><b>~$</b> <span className={styles.link} onClick={() => window.open('https://t.me/prohetamine', '_blank')}>Telegram</span></span>
            <span className={styles.overflowText} style={{ color: '#34a853', marginTop: '2px' }}><b>~$</b> <span className={styles.link} onClick={() => window.open('mailto:prohetamine@gmail.com', '_blank')}>prohetamine@gmail.com</span></span>
            <span className={styles.overflowText} style={{ color: '#fc3f1d', marginTop: '2px' }}><b>~$</b> <span className={styles.link} onClick={() => window.open('mailto:prohetamine@yandex.ru', '_blank')}>prohetamine@yandex.ru</span></span>
          </div>
        </motion.div>
        <motion.div
          className={styles.tapeBody}
          onTapStart={
            () =>
              isBrowser && setBlured(
                items =>
                  items.map(
                    (item, _key) =>
                      2 === _key
                        ? false
                        : true
                  )
              )
          }
          onTap={
            () =>
              isBrowser && setBlured(
                items =>
                  items.map(() => false)
              )
          }
          onHoverEnd={
            () =>
              isBrowser && setBlured(
                items =>
                  items.map(() => false)
              )
          }
          animate={{ filter: `blur(${blured[2] ? 10 : 0}px)`, zIndex: 99999 }}
          transition={{ duration: 0.2, delay: blured[2] ? 0.2 : 0 }}
        >
          <div className={styles.overflow}>
            <div className={styles.sectionTitle}>Продаю</div>
            <span className={styles.overflowText} style={{ color: '#fc8d31', marginTop: '2px' }}><b>~$</b> <span className={styles.link} onClick={() => window.open('https://www.livemaster.ru/stas-prohetamine', '_blank')}>Ярмарка Мастеров</span></span>
            <span className={styles.overflowText} style={{ color: '#f6de14', marginTop: '2px' }}><b>~$</b> <span className={styles.link} onClick={() => window.open('https://prohetamine.ru/merch', '_blank')}>Мерч</span></span>
          </div>
        </motion.div>
        <motion.div
          className={styles.tapeBody}
          onTapStart={
            () =>
              isBrowser && setBlured(
                items =>
                  items.map(
                    (item, _key) =>
                      3 === _key
                        ? false
                        : true
                  )
              )
          }
          onTap={
            () =>
              isBrowser && setBlured(
                items =>
                  items.map(() => false)
              )
          }
          onHoverEnd={
            () =>
              isBrowser && setBlured(
                items =>
                  items.map(() => false)
              )
          }
          animate={{ filter: `blur(${blured[3] ? 10 : 0}px)`, zIndex: 99999 }}
          transition={{ duration: 0.2, delay: blured[3] ? 0.2 : 0 }}
        >
          <div className={styles.overflow}>
            <div className={styles.sectionTitle}>Донаты</div>
            <span className={styles.overflowText} style={{ color: '#f58008', marginTop: '2px' }}><b>~$</b> <span className={styles.link} onClick={() => window.open('https://www.donationalerts.com/r/prohetamine', '_blank')}>Donation Alerts</span></span>
            <span className={styles.overflowText} style={{ color: '#ff424d', marginTop: '2px' }}><b>~$</b> <span className={styles.link} onClick={() => window.open('https://www.patreon.com/prohetamine', '_blank')}>Patreon</span></span>
          </div>
        </motion.div>
        <motion.div
          className={styles.tapeBody}
          onTapStart={
            () =>
              isBrowser && setBlured(
                items =>
                  items.map(
                    (item, _key) =>
                      4 === _key
                        ? false
                        : true
                  )
              )
          }
          onTap={
            () =>
              isBrowser && setBlured(
                items =>
                  items.map(() => false)
              )
          }
          onHoverEnd={
            () =>
              isBrowser && setBlured(
                items =>
                  items.map(() => false)
              )
          }
          animate={{ filter: `blur(${blured[4] ? 10 : 0}px)`, zIndex: 99999 }}
          transition={{ duration: 0.2, delay: blured[4] ? 0.2 : 0 }}
        >
          <div className={styles.overflow}>
            <div className={styles.sectionTitle}>Программирую</div>
            <span className={styles.overflowText} style={{ color: store.theme === 'light' ? '#24292f' : '#626c78' }}><b>~$</b> <span className={styles.link} style={{ color: store.theme === 'light' ? '#24292f' : '#626c78' }} onClick={() => window.open('https://github.com/prohetamine', '_blank')}>GitHub</span></span>
            <span className={styles.overflowText} style={{ color: '#cb3837', marginTop: '2px' }}><b>~$</b> <span className={styles.link} onClick={() => window.open('https://www.npmjs.com/~prohetamine', '_blank')}>NPM</span></span>
            <span className={styles.overflowText} style={{ color: '#319def', marginTop: '2px' }}><b>~$</b> <span className={styles.link} onClick={() => window.open('https://hub.docker.com/u/prohetamine', '_blank')}>Docker Hub</span></span>
            <span className={styles.overflowText} style={{ color: '#5962df', marginTop: '2px' }}><b>~$</b> <span className={styles.link} onClick={() => window.open('https://codesandbox.io/u/prohetamine', '_blank')}>CodeSandBox</span></span>
          </div>
        </motion.div>
        <motion.div
          className={styles.tapeBody}
          onTapStart={
            () =>
              isBrowser && setBlured(
                items =>
                  items.map(
                    (item, _key) =>
                      5 === _key
                        ? false
                        : true
                  )
              )
          }
          onTap={
            () =>
              isBrowser && setBlured(
                items =>
                  items.map(() => false)
              )
          }
          onHoverEnd={
            () =>
              isBrowser && setBlured(
                items =>
                  items.map(() => false)
              )
          }
          animate={{ filter: `blur(${blured[5] ? 10 : 0}px)`, zIndex: 99999 }}
          transition={{ duration: 0.2, delay: blured[5] ? 0.2 : 0 }}
        >
          <div className={styles.overflow}>
            <div className={styles.sectionTitle}>Пишу</div>
            <span className={styles.overflowText} style={{ color: store.theme === 'light' ? '#313b44' : '#698299', marginTop: '2px' }}><b>~$</b> <span className={styles.link} style={{ color: store.theme === 'light' ? '#313b44' : '#698299' }} onClick={() => window.open('https://habr.com/ru/users/prohetamine', '_blank')}>Хабр</span></span>
            <span className={styles.overflowText} style={{ color: '#8ac858', marginTop: '2px' }}><b>~$</b> <span className={styles.link} onClick={() => window.open('https://pikabu.ru/@prohetamine', '_blank')}>Пикабу</span></span>
            <span className={styles.overflowText} style={{ color: store.theme === 'light' ? '#191919' : '#d5d5d5', marginTop: '2px' }}><b>~$</b> <span className={styles.link} style={{ color: store.theme === 'light' ? '#191919' : '#d5d5d5' }} onClick={() => window.open('https://medium.com/@prohetamine', '_blank')}>Medium</span></span>
            <span className={styles.overflowText} style={{ color: '#b39da2', marginTop: '2px' }}><b>~$</b> <span className={styles.link} onClick={() => window.open('https://vc.ru/u/874009-stas-prohetamine', '_blank')}>VC</span></span>
            <span className={styles.overflowText} style={{ color: '#fe4501', marginTop: '2px' }}><b>~$</b> <span className={styles.link} onClick={() => window.open('https://www.reddit.com/user/prohetamine', '_blank')}>Reddit</span></span>
          </div>
        </motion.div>
        <motion.div
          className={styles.tapeBody}
          onTapStart={
            () =>
              isBrowser && setBlured(
                items =>
                  items.map(
                    (item, _key) =>
                      6 === _key
                        ? false
                        : true
                  )
              )
          }
          onTap={
            () =>
              isBrowser && setBlured(
                items =>
                  items.map(() => false)
              )
          }
          onHoverEnd={
            () =>
              isBrowser && setBlured(
                items =>
                  items.map(() => false)
              )
          }
          animate={{ filter: `blur(${blured[6] ? 10 : 0}px)`, zIndex: 99999 }}
          transition={{ duration: 0.2, delay: blured[6] ? 0.2 : 0 }}
        >
          <div className={styles.overflow}>
            <div className={styles.sectionTitle}>Снимаю и стримлю</div>
            <span className={styles.overflowText} style={{ color: '#ff0100', marginTop: '2px' }}><b>~$</b> <span className={styles.link} onClick={() => window.open('https://www.youtube.com/channel/UCFTKTphoCxkNU_88b-0Mq8g', '_blank')}>YouTube</span></span>
            <span className={styles.overflowText} style={{ color: '#24b6d2', marginTop: '2px' }}><b>~$</b> <span className={styles.link} onClick={() => window.open('https://www.tiktok.com/@prohetamine', '_blank')}>TikTok</span></span>
            <span className={styles.overflowText} style={{ color: '#a970ff', marginTop: '2px' }}><b>~$</b> <span className={styles.link} onClick={() => window.open('https://www.twitch.tv/prohetamine', '_blank')}>Twitch</span></span>
          </div>
        </motion.div>
        <motion.div
          className={styles.tapeBody}
          onTapStart={
            () =>
              isBrowser && setBlured(
                items =>
                  items.map(
                    (item, _key) =>
                      7 === _key
                        ? false
                        : true
                  )
              )
          }
          onTap={
            () =>
              isBrowser && setBlured(
                items =>
                  items.map(() => false)
              )
          }
          onHoverEnd={
            () =>
              isBrowser && setBlured(
                items =>
                  items.map(() => false)
              )
          }
          animate={{ filter: `blur(${blured[7] ? 10 : 0}px)`, zIndex: 99999 }}
          transition={{ duration: 0.2, delay: blured[7] ? 0.2 : 0 }}
        >
          <div className={styles.overflow}>
            <div className={styles.sectionTitle}>Социальные сети</div>
            <span className={styles.overflowText} style={{ color: '#0088cc', marginTop: '2px' }}><b>~$</b> <span className={styles.link} onClick={() => window.open('https://t.me/prohetamines', '_blank')}>Telegram Channel</span></span>
            <span className={styles.overflowText} style={{ color: '#0088cc', marginTop: '2px' }}><b>~$</b> <span className={styles.link} onClick={() => window.open('https://t.me/prohetamine', '_blank')}>Telegram</span></span>
            <span className={styles.overflowText} style={{ color: '#5865f2', marginTop: '2px' }}><b>~$</b> <span className={styles.link} onClick={() => window.open('https://discord.gg/A5348ndB', '_blank')}>Discord</span></span>
            <span className={styles.overflowText} style={{ color: '#bc3c7d', marginTop: '2px' }}><b>~$</b> <span className={styles.link} onClick={() => window.open('https://www.instagram.com/prohetamine', '_blank')}>Instagram</span></span>
            <span className={styles.overflowText} style={{ color: '#0077ff', marginTop: '2px' }}><b>~$</b> <span className={styles.link} onClick={() => window.open('https://vk.com/prohetamine', '_blank')}>VK</span></span>
            <span className={styles.overflowText} style={{ color: '#0077ff', marginTop: '2px' }}><b>~$</b> <span className={styles.link} onClick={() => window.open('https://vk.com/prohetamines', '_blank')}>VK Group</span></span>
            <span className={styles.overflowText} style={{ color: '#1d9bf0', marginTop: '2px' }}><b>~$</b> <span className={styles.link} onClick={() => window.open('https://twitter.com/prohetamine', '_blank')}>Twitter</span></span>
          </div>
        </motion.div>
      </div>
    </div>
  )
})

export default SocialTape
