const Achievements = () => {
  const title = 'Stas Prohetamine'
      , description = 'IT контент-мейкер aka Non-binary programmer — программист, дизайнер, писатель, преподаватель, разработчик электронных устройств, технический писатель, стартапер, хакер, скульптор, новый химический элемент и просто эксперт JavaScript.'

  return ({
    title: { tag: 'title', attributes: { innerHTML: title }},
    og_title: { tag: 'meta', attributes: { property: 'og:title', content: title }},
    author: { tag: 'meta', attributes: { name: 'author', content: title }},
    description: { tag: 'meta', attributes: { name: 'description', content: description }},
    og_description: { tag: 'meta', attributes: { property: 'og:description', content: description }},
    keywords: { tag: 'meta', attributes: { name: 'keywords', content: title }},
  })
}

export default Achievements
