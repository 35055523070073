import React, { useRef } from 'react'
import useWindowWidth from 'use-window-width'
import { Link } from 'react-router-dom'
import { observer } from 'mobx-react-lite'
import { motion } from 'framer-motion'
import cyrillicToTranslit from 'cyrillic-to-translit-js'

import mainStyles from './main.module.css'
import lightStyles from './light.module.css'
import darkStyles from './dark.module.css'
import globalLightStyles from './../../../styles/light.module.css'
import globalDarkStyles from './../../../styles/dark.module.css'

const dark = { ...globalDarkStyles, ...mainStyles, ...darkStyles }
    , light = { ...globalLightStyles, ...mainStyles, ...lightStyles }

import store from './../../../../../store'

const Column = observer(({ style, data, path }) => {
  const styles = store.theme === 'light'
                  ? light
                  : dark

  const refScroll = useRef()

  return (
    <div
      className={styles.column}
      ref={refScroll}
      style={style}
    >
      <div>
        {
          data.map(
            ({ image, title, titleShort = '...' }, _key) => (
              <motion.div
                className={styles.link}
                key={_key}
                initial={{ scale: 1 }}
                whileHover={{ scale: 0.95 }}
                whileTap={{ scale: 1 }}
              >
                <Link to={`${path}/${cyrillicToTranslit().transform(title.toLowerCase(), '-').replace(/[^\w]/gi, '-')}`}>
                  <img
                    className={styles.linkImg}
                    src={image}
                    onDragStart={e => e.preventDefault()}
                  />
                  <motion.div
                    className={styles.linkOverflov + ' ' + styles.description}
                    initial={{ opacity: 0 }}
                    whileHover={{ opacity: 1 }}
                    whileTap={{ opacity: 0 }}
                    style={{ fontWeight: 600 }}
                  >
                    {titleShort}
                  </motion.div>
                </Link>
              </motion.div>
            )
          )
        }
      </div>
    </div>
  )
})

const Cards = observer(({ items, path }) => {
  const styles = store.theme === 'light'
                  ? light
                  : dark

  const refWidth = useRef()
      , windowWidth = useWindowWidth()


  const rowCount = windowWidth > 800 ? 5 : 2
  const chunkLength = parseInt(items.length / rowCount) || 1

  const data = items.reduce((ctx, elem, key) => {
    if (ctx.length === 0) {
      ctx.push([])
    }

    if (ctx.length === rowCount && ctx[ctx.length - 1].length === chunkLength) {
      ctx[key % ctx.length].push(elem)
      return ctx
    }

    if (ctx[ctx.length - 1].length === chunkLength) {
      ctx.push([elem])
    } else {
      ctx[ctx.length - 1].push(elem)
    }

    return ctx
  }, [])

  return (
    <div className={styles.body}>
      <div className={styles.wrapper} ref={refWidth}>
        {
          data.map((chunk, key) =>
            <Column
              key={key}
              path={path}
              data={chunk}
            />
          )
        }
      </div>
    </div>
  )
})

export default Cards
