import React, { useEffect } from 'react'
import { observer } from 'mobx-react-lite'
//import useWindowWidth from 'use-window-width'

import lightStyles from './styles/light.module.css'
import darkStyles from './styles/dark.module.css'

import store from './../../store'

import ButtonLink from './components/button-link'
import Social from './components/social'
import MaxCards from './components/tapes/max-cards'
import NumberedText from './components/tapes/numbered-text'
import SmallCards from './components/tapes/small-cards'
import MovingCards from './components/tapes/moving-cards'
//`import Sound from './components/tapes/sound'
import ContactsConsole from './components/contacts-console'
//import Radio from './components/radio'
import Footer from './components/footer'

import useCastumStyle from './../../lib/use-castum-style'

import setHead from 'generatamine/libs/setHead'
import header from './../../headers/home'

//import lentaIcon from './../../assets/icons/lenta.png'
import merchIcon from './../../assets/icons/merch.png'

import mainItems from './../../main-items.js'

const otherItems = [/*{
  image: lentaIcon,
  title: 'Лента новостей',
  description: 'Заметки, новости, анонсы, мемы, важные и не только (только).',
  link: ['/lenta', 'Открыть'],
},*/ {
  image: merchIcon,
  title: 'Мерч',
  description: 'Мой мерч — самый уникальный мерч.',
  link: ['/merch', 'Открыть'],
}, ...mainItems]

const Home = observer(() => {
  useCastumStyle()

  useEffect(() => {
    setHead(
      header()
    )
  }, [])

  const styles = store.theme === 'light'
                  ? lightStyles
                  : darkStyles

  //const width = useWindowWidth()

  return (
    <div className={styles.wrapper}>
      {
        /*
          <Radio className={width < 640 ? '' : styles.titleMarginTop} />
          <ButtonLink to='/service/radio-boomer-fm'>Мое онлайн радио</ButtonLink>
        */
      }
      <h1 className={styles.titleH1} name='main-me'>Да кто он такой этот ваш..</h1>
      <p className={styles.description} dangerouslySetInnerHTML={{ __html: 'С самого детства я увлекался электроникой, паял и собирал всякое, потом <selection>разочаровался</selection> и увлекся программированием.' }} />
      <p className={styles.quote}>Всю жизнь я создаю, экспериментирую, ломаю — наверно это самое точное описание.</p>
      <p className={styles.description}>Открыл для себя двери программрования где-то в <b>13-14 лет</b> но код еще не писал, это было что-то вроде переставления строк чужого кода в <b>html</b>, но по мимо этого я создавал игры и какое-то подобие сайтов на <a className={styles.link} href="https://ru.wikipedia.org/wiki/Adobe_Flash"><b>Flash</b></a>, <a className={styles.link} href="https://ru.wikipedia.org/wiki/ActionScript"><b>Action-Script 2.0</b></a> и <a className={styles.link} href="https://cyclowiki.org/wiki/3D_Rad"><b>3D Rad</b></a> — был такой простенький игровой движок.</p>
      <p className={styles.description} dangerouslySetInnerHTML={{ __html: 'Затем в мою жизнь ворвался <b>php</b> и моя жизнь уже никогда не была прежней (нет), как бы не так, на самом деле все что меня связывает с php это несколько скромных разработок, среди них: <i>чат</i> и <i>игра</i>. Действительно переломный момент в моей жизни наступил когда я познакомился с <selection>JavaScript</selection> — этот язык и стал моим основным языком программирования. Но все этим не кончается, также был у меня небольшой опыт с <b>Python</b> и <b>C++</b>.' }} />
      <p className={styles.description}>В <b>15 лет</b> это увлечение стало хоть сколько-то серьёзным, я начал писать первый код, а через некоторое время получил первый заказ. В 18 лет я написал свои первые несколько статей на тему разработки и получил долю в бизнесе всего за одну ночь.</p>
      <p className={styles.description}>В целом я прошел достаточно большой путь от неудач до каких-то призрачных успехов, в моей истории были слезы и сожаления потерянного времени, но были и успехи которым я сам завидовал некоторую часть из них перечислю <a className={styles.link} href='/#my-progress'>тут</a>. <s>Мне нравится то что я делаю и я буду делать дальше то что хочу</s>.</p>
      <p className={styles.quote}><s>Мой путь сложился так что <b>я живу в своем мире</b> где не существует работы — но есть прикольные проекты которые так или иначе приносят доход.</s></p>
      <p className={styles.description} dangerouslySetInnerHTML={{ __html: '<s>У меня <selection>нет резюме</selection> и писать я его не намерен, но у меня есть <a className={styles.link} href="https://github.com/prohetamine">GitHub</a> и там можно найти мой код за <i>последний год</i> также небольшие кусочки прошлого. Готов обсуждать реально <b>интересные</b> проекты, <selection>стартапы</selection>, также какой-то фриланс и другие предложения, деньги <b>не решают</b> их у меня есть, <b>мне должно быть интересно</b>.</s>' }} />
      <p className={styles.description} style={{ border: '60px solid #f3dc1e', padding: '15px', boxSizing: 'border-box' }} dangerouslySetInnerHTML={{ __html: '<selection>Время идет и все меняется</selection> как не грустно это признавать. На момент <b>22.07.2023</b> <i>активного</i> желания продолжать общественную деятельность нет, причин к этому много, жизнь стала другой, полюса поменялись, карта снова перевернулась и вообще: <b>«...я столкнулся с такими последствиями, с которыми я в своей истории ещё никогда не сталкивался»</b> Если не говорить загадками то все просто, мне нужна работа, зовите на работу.' }} />
      <h1 className={styles.titleH1} name='work'>Чем я занимаюсь</h1>
      <p className={styles.description}><b>IT контент-мейкер</b> — <i>программист, дизайнер, писатель, преподаватель, разработчик электронных устройств, технический писатель, стартапер, хакер, скульптор, новый химический элемент и просто <strike>эксперт</strike> JavaScript.</i> За всей моей активностью можно наблюдать <a className={styles.link} href='/#social'>тут</a> или на этом сайте.</p>
      <h1 className={styles.titleH1} name='service'>Мои сервисы</h1>
      <p className={styles.description} dangerouslySetInnerHTML={{ __html: 'Очень жаль что время стирает память и части истории становятся уже ничем, как будто их никогда и не было. Тут будут сервисы которые жили когда-то или живут и сейчас, а также те которые по тем или иным причинам я не запустил или только планирую в обозримом будущем и вообщем <selection>мои</selection> самые популярные идеи.' }} />
      <MovingCards items={store.api.services || []} repeat={3} path='/service' />
      <ButtonLink to='/services'>Смотреть все сервисы</ButtonLink>
      <a className={styles.titleH1} name='projects'>Мои проекты</a>
      <p className={styles.description} dangerouslySetInnerHTML={{ __html: 'Отвечая на частый вопрос: <selection>Я не всегда зарабатываю на этом</selection>, восновном это как развлечение иногда получаю предложения. Но в основном я просто люблю программировать, как сказал великий: <b>“Ебашить сама цель”</b>. В данный момент я реализую свои идеи и верю что одна из них выстрелит, я найду (или он найдет меня) инвестора и мы заработаем много бумажных <selection>деняк</selection>.' }} />
      <p className={styles.quote}>Если просто хочешь сделать саппорт, тебе нравятся мои идеи или проекты, используешь мой софт и <b>хочешь поддержать</b> — <a className={styles.link} href='https://www.donationalerts.com/r/prohetamine'>сделай донат</a>.</p>
      <MovingCards items={store.api.projects || []} repeat={3} path='/project' />
      <ButtonLink to='/projects'>Смотреть все проекты</ButtonLink>
      <h1 className={styles.titleH1} name='articles'>Статьи</h1>
      <p className={styles.description}>Пишу о своем деле. О идеях и проектах, некоторые из моих статей призваны быть <b>руководством к действию</b>, а какие-то могут быть и <b>провокацией</b>. Вообще писать статьи — это очень тяжело и трудно, мне не платят, я делаю это просто потому что это важно.</p>
      <p className={styles.quote}>«Знаете, интернет научил меня всему, что я знаю и даже больше, чем просто всему. Интернет стал не просто учением, в котором тяжело, но и боем, в котором легко. И я благодарен всем, кто так или иначе принял участие в моем обучении, через статьи, описание каких-то технологий, видео на YouTube и просто критику моих работ. Это герои моего времени, только благодаря им я сейчас являюсь неплохим специалистом. Ведь я не учился в этих ваших институтах и образований не получал, да и всего у меня 9 классов. Спасибо тем, кто пишет интернет.» — Вступление к статье <b><a href='https://habr.com/ru/post/593421/'>“Электроника всем начинающим”</a></b></p>
      <MaxCards items={store.api.articles ? store.api.articles.slice(0, 3) : []} target='_blank' />
      <ButtonLink to='/articles'>Смотреть все статьи</ButtonLink>
      <h1 className={styles.titleH1} name='my-progress'>Мои достижения</h1>
      <p className={styles.description}>То чем я так или наче горжусь или с ухмылкой вспоминаю. <b>Дисклеймер</b> всему верить не стоит, некоторая часть является выдумкой и все совпадения случайны, код на который я ссылаюсь был занесен мне в репозитории неизвестным вирусом и не удаляется.</p>
      <NumberedText items={store.api.achievements ? store.api.achievements.slice(0, 5) : []} />
      <ButtonLink to='/achievements'>Смотреть все достижения</ButtonLink>
      {/*<p className={styles.description}>Тут я публикую истории. <b>Дисклеймер</b> которые являются лишь выдумкой, весь материал прикрепленный является фото и видеомонтажем, любые совпадения с реальными людьми, компаниями и событиями просто случайны, в целом все сказанное стоит рассматривать и использовать лишь в целях наблюдения за психически не здоровым человеком с манией величия. Не воспринимайте серьезно. Не вскрывайте эту тему ребята.</p>
      <Sound items={store.api.historySound ? store.api.historySound.slice(0, 3) : []} />
      <ButtonLink to='/history'>Больше охуительных историй</ButtonLink>*/}
      <h1 className={styles.titleH1} name='social'>Мои соц сети</h1>
      <p className={styles.description}>Я публикую в соц сети, веду стримы, снимаю видео, пишу код, также со мной можно связаться.</p>
      <Social />
      <h1 className={styles.titleH1} name='contacts'>Контакты</h1>
      <p className={styles.description}>Так сложилось что я не использую телефоный номер для связи, поэтому связаться со мной можно только в <a className={styles.link} href='#social'>соц сетях</a> и <a className={styles.link} href='#social'>мессенджерах</a>, лучше писать сразу в <a className={styles.link} href='https://t.me/prohetamine'>телеграм</a> или на <a className={styles.link} href='mailto:prohetamine@gmail.com'>почту</a>.</p>
      <p className={styles.quote}><b>Telegram</b> — универсальный способ связи со мной, другие варианты могут занять больше времени.</p>
      <p className={styles.description} dangerouslySetInnerHTML={{ __html: 'Так же можно отправить мне сообщение через коммандную строку, для начала ведите: <selection>-h</selection> или <selection>--help</selection> или используйте другие комманды например: <selection>exit</selection> чтобы закрыть терминал и перейти в обычную форму.' }} />
      <ContactsConsole />
      <h1 className={styles.titleH1}>Что-то ещё</h1>
      <SmallCards items={otherItems} />
      <Footer />
    </div>
  )
})

export default Home
