import { useEffect } from 'react'
import { animateScroll } from 'react-scroll'
import { useLocation } from 'react-router-dom'

const useScrollToTop = () => {
  const location = useLocation()

  useEffect(() => {
    animateScroll.scrollTo(0, { duration: 300 })
  }, [location.pathname])
}

export default useScrollToTop
