import React, { useState, useEffect } from 'react'
import { observer } from 'mobx-react-lite'
import { motion, AnimatePresence } from 'framer-motion'

import lightStyles from './light.module.css'
import darkStyles from './dark.module.css'
import globalLightStyles from './../../styles/light.module.css'
import globalDarkStyles from './../../styles/dark.module.css'

const dark = { ...globalDarkStyles, ...darkStyles }
    , light = { ...globalLightStyles, ...lightStyles }

import store from './../../../../store'

import yellowHeartIcon from './../../../../assets/icons/yellow-heart.png'
import greenHeartIcon from './../../../../assets/icons/green-heart.png'
import blueHeartIcon from './../../../../assets/icons/blue-heart.png'
import orangeHeartIcon from './../../../../assets/icons/orange-heart.png'
import redHeartIcon from './../../../../assets/icons/red-heart.png'
import purpleHeartIcon from './../../../../assets/icons/purple-heart.png'

const hearts = [
  purpleHeartIcon,
  blueHeartIcon,
  greenHeartIcon,
  yellowHeartIcon,
  orangeHeartIcon,
  redHeartIcon,
]

const Footer = observer(() => {
  const styles = store.theme === 'light'
                  ? light
                  : dark

  const [heartIndex, setHeartIndex] = useState(0)

  useEffect(() => {
    const timeId = setInterval(() => {
      setHeartIndex(i => {

        if (i > 4) {
          return 0
        }

        i++
        return i
      })
    }, 2500)

    return () => clearInterval(timeId)
  }, [])

  return (
    <div
      className={styles.description + ' ' + styles.footer}
    >
      Сделано с
      <div className={styles.heartOverflow}>
        <AnimatePresence>
          <motion.img
            className={styles.heart}
            src={hearts[heartIndex]}
            key={heartIndex}
            initial={{ opacity: 0, y: 20, rotate: -100 }}
            animate={{ opacity: 1, y: 0, rotate: 0 }}
            exit={{ opacity: 0, y: -35, rotate: 200 }}
            transition={{ duration: 0.5 }}
          />
        </AnimatePresence>
      </div>
      клавиатуры, в 2к22 году.
    </div>
  )
})

export default Footer
