import React, { useState, useEffect } from 'react'
import queryString from 'query-string'
import { useLocation } from 'react-router-dom'
import { observer } from 'mobx-react-lite'

import mainStyles from './styles/main.module.css'
import lightStyles from './styles/light.module.css'
import darkStyles from './styles/dark.module.css'

const light = { ...mainStyles, ...lightStyles }
    , dark = { ...mainStyles, ...darkStyles }

import store from './../../store'

import Cards from './components/tapes/cards'
import Filter from './components/filter'
import SmallCards from './components/tapes/small-cards'
import Footer from './components/footer'

import useScrollToTop from './../../lib/use-scroll-to-top'
import useCastumStyle from './../../lib/use-castum-style'

import setHead from 'generatamine/libs/setHead'
import header from './../../headers/projects'

import servicesIcon from './../../assets/icons/service.png'
import merchIcon from './../../assets/icons/merch.png'

import mainItems from './../../main-items.js'

const otherItems = [{
  image: servicesIcon,
  title: 'Мои сервисы',
  description: 'Очень жаль что время стирает память и части ...',
  link: ['/services', 'Открыть'],
}, {
  image: merchIcon,
  title: 'Мерч',
  description: 'Мой мерч — самый уникальный мерч.',
  link: ['/merch', 'Открыть'],
}, ...mainItems]

const Projects = observer(() => {
  useScrollToTop()
  useCastumStyle()

  useEffect(() => {
    setHead(
      header()
    )
  }, [])

  const styles = store.theme === 'light'
                  ? light
                  : dark

  const location = useLocation()
  const query = queryString.parse(location.search)
  const [tag, setTag] = useState(query.tag || 'Все')

  const items = store.api.projects || []

  const tags = Object.keys(
    items.map(({ tags }) =>
      tags.map(tags => tags)
    ).flat().reduce((ctx, tag) => {
      ctx[tag] = ''
      return ctx
    }, {})
  )

  const _items = items.filter(item => tag !== 'Все' ? item.tags.find(_tag => _tag === tag) : true)

  return (
    <div className={styles.wrapper}>
      <h1 className={styles.titleH1 + ' ' + styles.titleMarginTop}>Мои проекты</h1>
      <p className={styles.description}  dangerouslySetInnerHTML={{ __html: 'Отвечая на частый вопрос: <selection>Я не всегда зарабатываю на этом</selection>, восновном это как развлечение иногда получаю предложения. Но в основном я просто люблю программировать, как сказал великий: <b>“Ебашить сама цель”</b>. В данный момент я реализую свои идеи и верю что одна из них выстрелит, я найду (или он найдет меня) инвестора и мы заработаем много бумажных <selection>деняк</selection>.' }} />
      <p className={styles.quote}>Если просто хочешь сделать саппорт, тебе нравятся мои идеи или проекты, используешь мой софт и <b>хочешь поддержать</b> — <a className={styles.link} href='https://www.donationalerts.com/r/prohetamine'>сделай донат</a>.</p>
      <Filter tags={tags} activeTag={tag} onTag={tag => setTag(tag)} />
      <Cards items={_items} path='/project' />
      <h1 className={styles.titleH1}>Что-то ещё</h1>
      <SmallCards items={otherItems} />
      <Footer />
    </div>
  )
})

export default Projects
