import React, { useState, useEffect } from 'react'
import { observer } from 'mobx-react-lite'
import { motion } from 'framer-motion'
import { isBrowser } from 'react-device-detect'

import mainStyles from './main.module.css'
import lightStyles from './light.module.css'
import darkStyles from './dark.module.css'
import globalLightStyles from './../../../styles/light.module.css'
import globalDarkStyles from './../../../styles/dark.module.css'

const dark = { ...mainStyles, ...globalDarkStyles, ...darkStyles }
    , light = {  ...mainStyles, ...globalLightStyles, ...lightStyles }

import store from './../../../../../store'

const TextTape = observer(({ items }) => {
  const styles = store.theme === 'light'
                    ? light
                    : dark

  const [_items, setItems] = useState(
    items.map(item => ({ ...item, blured: false }))
  )

  useEffect(() => {
    setItems(
      items.map(item => ({ ...item, blured: false }))
    )
  }, [items.map(e => e.title).join('')])

  return (
    <div className={styles.body}>
      <div className={styles.wrapper}>
        {
          _items.map(({ text, blured }, key) =>
            <motion.div
              className={styles.tapeBody}
              onTapStart={
                () =>
                  isBrowser && setItems(
                    items =>
                      items.map(item => ({ ...item, blured: item.text !== text }))
                  )
              }
              onTap={
                () =>
                  isBrowser && setItems(
                    items =>
                      items.map(item => ({ ...item, blured: false }))
                  )
              }
              onHoverEnd={
                () =>
                  isBrowser && setItems(
                    items =>
                      items.map(item => ({ ...item, blured: false }))
                  )
              }
              key={key}
              theme={store.theme}
              animate={{ filter: `blur(${blured ? 10 : 0}px)` }}
              transition={{ duration: 0.2, delay: blured ? 0.2 : 0 }}
              style={
                key === 0
                  ? { marginTop: '0px', marginBottom: '20px' }
                  : { marginTop: '0px', marginBottom: key === items.length - 1 ? '0px' : '20px' }
              }
            >
              <div className={styles.overflow}>
                <div
                  className={styles.description}
                  style={{ marginBottom: '0px', display: 'flex', cursor: 'text' }}
                >
                  <b className={styles.boldNumber}>{key+1}.</b>
                  <span
                    style={{ marginLeft: '10px' }}
                    dangerouslySetInnerHTML={{ __html: text }}
                  />
                </div>
              </div>
            </motion.div>
          )
        }
      </div>
    </div>
  )
})

export default TextTape
