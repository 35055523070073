import React, { useState, useEffect } from 'react'
import { motion } from 'framer-motion'
import useWindowWidth from 'use-window-width'
import useScrollPosition from '@react-hook/window-scroll'
import { observer } from 'mobx-react-lite'
import { Link, useLocation, useNavigate } from 'react-router-dom'
import { scroller, Events } from 'react-scroll'

import lightStyles from './light.module.css'
import darkStyles from './dark.module.css'

import store from './../../store'

import ThemeButton from './theme-button'

import avatar from './../../assets/images/avatar.svg'

import mainMeIcon from './../../assets/icons/main_me.png'
import workIcon from './../../assets/icons/work.png'
import serviceIcon from './../../assets/icons/service.png'
import projectsIcon from './../../assets/icons/projects.png'
import articlesIcon from './../../assets/icons/articles.png'
import myProgressIcon from './../../assets/icons/my_progress.png'
//import historyIcon from './../../assets/icons/history.png'
import socialIcon from './../../assets/icons/social.png'
import contactsIcon from './../../assets/icons/contacts.png'
import merchIcon from './../../assets/icons/merch.png'
//import lentaIcon from './../../assets/icons/lenta.png'

import lightHintArrowIcon from './../../assets/icons/light-hint-arrow.svg'
import darkHintArrowIcon from './../../assets/icons/dark-hint-arrow.svg'

const Hint = observer(({ isShow, onHide, children, href }) => {
  const styles = store.theme === 'light'
                  ? lightStyles
                  : darkStyles

  return (
    <motion.span
      className={styles.hintBody}
      animate={
        isShow
          ? {
            opacity: 1,
            transform: 'scale(1)'
          }
          : {
            opacity: 0,
            transform: 'scale(0)'
          }
      }
    >
      <Link
        style={{ display: 'contents' }}
        to={href}
        onClick={onHide}
      >
        <div
          className={styles.hintIcons}
          style={{
            backgroundImage: `url(${store.theme === 'light' ? lightHintArrowIcon : darkHintArrowIcon})`,
            backgroundSize: 'cover'
          }}
        />
        <div className={styles.hintText}>{children}</div>
      </Link>
    </motion.span>
  )
})

const NavigationMenu = [{
    href: '/#main-me',
    icon: mainMeIcon,
    text: 'О себе'
  }, {
    href: '/#work',
    icon: workIcon,
    text: 'Чем я занимаюсь'
  }, {
    href: '/#service',
    icon: serviceIcon,
    text: 'Мои сервисы'
  }, {
    href: '/#projects',
    icon: projectsIcon,
    text: 'Мои проекты'
  }, {
    href: '/#articles',
    icon: articlesIcon,
    text: 'Статьи'
  }, {
    href: '/#my-progress',
    icon: myProgressIcon,
    text: 'Мои достижения'
  }, /*{
    href: '/#history',
    icon: historyIcon,
    text: 'Охуительные истории'
  },*/ {
    href: '/#social',
    icon: socialIcon,
    text: 'Мои соц сети'
  }, {
    href: '/#contacts',
    icon: contactsIcon,
    text: 'Контакты'
  }, {
    line: true
  },
  /*{
    href: '/lenta',
    icon: lentaIcon,
    text: 'Лента новостей'
  },*/ {
    href: '/merch',
    icon: merchIcon,
    text: 'Мерч'
  }
]

const Menu = observer(() => {
  const styles = store.theme === 'light'
                  ? lightStyles
                  : darkStyles

  const width = useWindowWidth() - 20
      , scrollY = useScrollPosition(10)

  const [hints, setHints] = useState(Array(NavigationMenu.length).fill(false))
  const [scrolling, setScrolling] = useState(true)

  const location = useLocation()
      , navigate = useNavigate()

  useEffect(() => {
    const hash = location.hash
    
    if (hash.match(/#/)) {
      const elementId = hash.replace(/#/, '')
      scroller.scrollTo(elementId, {
        smooth: true,
        offset: -50
      })
    }
  }, [store.load, location.hash])

  useEffect(() => {
    const handler = ({ path }) => {
      if (path && path[0].tagName === 'DIV') {
        setHints(
          Array(NavigationMenu.length)
            .fill(false)
        )
      }
    }

    window.addEventListener('click', handler)
    return () => window.removeEventListener('click', handler)
  }, [])

  useEffect(() => {
    if (!location.hash) {
      Events.scrollEvent.register('end', () => {
        const handler = () => {
          setScrolling(false)
          window.removeEventListener('scroll', handler)
        }
        setTimeout(() => {
          window.addEventListener('scroll', handler)
        }, 5000)
      })

      Events.scrollEvent.register('begin', () => {
        setScrolling(true)
      })

      return () => {
        Events.scrollEvent.remove('end')
        Events.scrollEvent.remove('begin')
      }
    }
  }, [location.hash])

  useEffect(() => {
    if (!scrolling) {
      const pathname = location.pathname
      navigate(pathname)
    }
  }, [scrollY, scrolling])

  const isMenu = scrollY > (width >= 1200 ? 969 : width >= 640 ? 914 : 715.5)

  useEffect(() => {
    store.isMenu = isMenu
  }, [isMenu])

  return (
    <div
      className={styles.body}
      style={
        store.isMenu
          ? {
            width: '0px',
            maxWidth: '0px',
            minWidth: '0px',
            padding: '0px',
            opacity: 0
          }
          : {
            opacity: 1
          }
      }
    >
      <img className={styles.avatar} src={avatar} draggable={false} onClick={() => window.location.href = '/'} />
      <div className={styles.name}>Stas Prohetamine</div>
      <div className={styles.description}>Мне {(new Date()).getFullYear() - 1999} года, живу в России и 6+ лет что-то делаю в IT.</div>
      <div className={styles.line} />
      <nav className={styles.navigation}>
        {
          NavigationMenu.map(({ href, icon, text, line }, key) => (
            line
              ? (
                <div key={key} className={styles.line} />
              )
              : (
                <div key={key} className={styles.button} style={key === 0 ? { marginTop: '0px' } : {}}>
                  <Link
                    className={styles.link}
                    style={key === 0 ? { marginTop: '0px' } : {}}
                    to={href}
                    name='main-link'
                    onClick={e => {
                      width < 1200 ? e.preventDefault() : null
                      setHints(Array(NavigationMenu.length).fill(false).map((_, _key) => _key === key ? true : false))
                    }}
                  >
                    <img
                      className={styles.linkIcon}
                      src={icon}
                    />
                    <div className={styles.linkText}>{text}</div>
                  </Link>
                  <Hint
                    isShow={hints[key]}
                    onHide={() => setHints(Array(NavigationMenu.length).fill(false))}
                    href={href}
                  >
                    {text}
                  </Hint>
                </div>
              )
          ))
        }
      </nav>
      <div className={styles.line} />
      <ThemeButton />
      <div className={styles.line+' '+styles.lineEnd} />
      <p className={styles.miniText}>Благодаря любви, которую люди испытывают к тебе, твое дело может продолжаться вечно. <span className={styles.miniTextInverse}>Мерикара.</span></p>
    </div>
  )
})

export default Menu
