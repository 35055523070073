const Default = () => {
  return ({
    encoding: { tag: 'meta', attributes: { charset: 'utf-8' }},
    'http-equiv': { tag: 'meta', attributes: { 'http-equiv': 'X-UA-Compatible', content: 'IE=edge' }},
    viewport: { tag: 'meta', attributes: { name: 'viewport', content: 'width=device-width, initial-scale=1, maximum-scale=1, user-scalable=0' }},
    'theme-color': { tag: 'meta', attributes: { name: 'theme-color', content: '#000000' }},
    og_image: { tag: 'meta', attributes: { property: 'og:image', content: '%%SITE%%/screenshot.png' }},
    og_image_url: { tag: 'meta', attributes: { property: 'og:image:url', content: '%%SITE%%/screenshot.png' }},
    og_image_secure_url: { tag: 'meta', attributes: { property: 'og:image:secure_url', content: '%%SITE%%/screenshot.png' }},
    og_image_type: { tag: 'meta', attributes: { property: 'og:image:type', content: 'image/png' }},
    og_image_width: { tag: 'meta', attributes: { property: 'og:image:width', content: '1280' }},
    og_image_height: { tag: 'meta', attributes: { property: 'og:image:height', content: '720' }},
    og_url: { tag: 'meta', attributes: { property: 'og:url', content: '%%SITE%%' }},
    og_type: { tag: 'meta', attributes: { property: 'og:type', content: 'website' }},
    og_locale: { tag: 'meta', attributes: { property: 'og:locale', content: 'ru_RU' }},
    image_src: { tag: 'link', attributes: { rel: 'image_src', href: '/logo512.png' }},
    'apple-touch-icon': { tag: 'link', attributes: { rel: 'apple-touch-icon', href: '/logo192.png' }},
    manifest: { tag: 'link', attributes: { rel: 'manifest', href: '/manifest.json' }},
    favicon: { tag: 'link', attributes: { rel: 'icon', type: 'image/svg+xml', href: '/favicon.svg' }},
    googleapis: { tag: 'link', attributes: { rel: 'preconnect', href: 'https://fonts.googleapis.com' }},
    gstatic: { tag: 'link', attributes: { rel: 'preconnect', href: 'https://fonts.gstatic.com', crossorigin: true }},
    fonts: { tag: 'link', attributes: { rel: 'stylesheet', href: 'https://fonts.googleapis.com/css2?family=Bebas+Neue&family=Festive&family=Roboto:ital,wght@0,100;0,300;0,400;0,500;0,700;0,900;1,100;1,300;1,400;1,500;1,700;1,900&display=swap' }},
  })
}

export default Default
