const Achievements = () => {
  const author = 'Stas Prohetamine'
      , title = `${author} | Достижения`
      , description = 'То чем я так или наче горжусь или с ухмылкой вспоминаю, согласно описанию блока с охуительными историями также всему верить не стоит, некоторая часть является выдумкой и все совпадения случайны, код на который я ссылаюсь был занесен мне в репозитории неизвестным вирусом и не удаляется.'

  return ({
    title: { tag: 'title', attributes: { innerHTML: title }},
    og_title: { tag: 'meta', attributes: { property: 'og:title', content: title }},
    author: { tag: 'meta', attributes: { name: 'author', content: author }},
    description: { tag: 'meta', attributes: { name: 'description', content: description }},
    og_description: { tag: 'meta', attributes: { property: 'og:description', content: description }},
    keywords: { tag: 'meta', attributes: { name: 'keywords', content: title }},
  })
}

export default Achievements
