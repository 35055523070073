const Projects = () => {
  const author = 'Stas Prohetamine'
      , title = `${author} | Мои проекты`
      , description = 'Я не всегда зарабатываю на этом, восновном это как развлечение иногда получаю предложения. Я просто люблю программировать, как сказал великий: “Ебашить сама цель”. В данный момент я реализую свои идеи и верю что одна из них выстрелит, я найду (или он найдет меня) инвестора и мы заработаем много бумажных деняк. Ну, а если ты просто хочешь сделать саппорт, тебе нравятся мои идеи или проекты, используешь мой софт и хочешь поддержать — сделай донат.'

  return ({
    title: { tag: 'title', attributes: { innerHTML: title }},
    og_title: { tag: 'meta', attributes: { property: 'og:title', content: title }},
    author: { tag: 'meta', attributes: { name: 'author', content: author }},
    description: { tag: 'meta', attributes: { name: 'description', content: description }},
    og_description: { tag: 'meta', attributes: { property: 'og:description', content: description }},
    keywords: { tag: 'meta', attributes: { name: 'keywords', content: title }},
  })
}

export default Projects
