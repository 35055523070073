import React from 'react'
import { observer } from 'mobx-react-lite'

import mainStyles from './main.module.css'
import lightStyles from './light.module.css'
import darkStyles from './dark.module.css'
import globalLightStyles from './../../styles/light.module.css'
import globalDarkStyles from './../../styles/dark.module.css'

const dark = { ...globalDarkStyles, ...mainStyles, ...darkStyles }
    , light = { ...globalLightStyles, ...mainStyles, ...lightStyles }

import store from './../../../../store'

const Radio = observer(() => {
  const styles = store.theme === 'light'
                  ? light
                  : dark

  return (
    <div className={styles.body}>
      <div className={styles.wrapper}>
        <iframe src="https://prohetamine.github.io/radio-boomer/?frame=1" className={styles.radio}></iframe>
      </div>
    </div>
  )
})

export default Radio
