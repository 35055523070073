const Lenta = () => {
  const author = 'Stas Prohetamine'
      , title = `${author} | Лента новостей`
      , description = 'Заметки, новости, анонсы, мемы, важные и не только (только).'

  return ({
    title: { tag: 'title', attributes: { innerHTML: title }},
    og_title: { tag: 'meta', attributes: { property: 'og:title', content: title }},
    author: { tag: 'meta', attributes: { name: 'author', content: author }},
    description: { tag: 'meta', attributes: { name: 'description', content: description }},
    og_description: { tag: 'meta', attributes: { property: 'og:description', content: description }},
    keywords: { tag: 'meta', attributes: { name: 'keywords', content: title }},
  })
}

export default Lenta
